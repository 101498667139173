import React from "react";
import { SVGS } from "../../assets/svgs";
import { WhyPosCard } from "../cards/card";
import { Col, Row } from "../containers/containers";
import { HeaderText, NormalTexts } from "../texts/texts";
import styles from "./styles.module.css";

const { headset, light, time } = SVGS;
export const WhyUsePos = ({ addclass, reference }) => {
  return (
    <Row refe={reference} className={`${styles.WhyContainer}`}>
      <Col className={styles.subcontainer}>
        <Col
          className={`${styles.ff} ${"slideUp_no"}`}
          justify="flex-start"
          align="flex-start"
        >
          <HeaderText size={36} align="left">
            Why use WayaPOS
          </HeaderText>

          <NormalTexts align="left" color="#858585" size={20}>
            The Point of Sales (POS) terminal is a portable electronic device
            that processes payments for goods and services using the{" "}
            {`customer’s`} local debit card.
          </NormalTexts>
        </Col>
        <Row className={`${styles.cards} minicard`} justify="space-between">
          <div className={"slideUp_no"}>
            <WhyPosCard
              className="minicard "
              text={`Instant Settlement`}
              icon={time}
            />
          </div>
          <div className={"slideUp"}>
            <WhyPosCard
              className="minicard "
              text={`Fast and Reliable Network `}
              icon={light}
            />
          </div>
          <div className={"slideUp_2_0"}>
            <WhyPosCard
              className="minicard "
              text={`Great Customer Support`}
              icon={headset}
            />
          </div>
          <div className={"slideUp_2_0"}>
            <WhyPosCard
              className="minicard "
              text={`Secure Payments`}
              icon={headset}
            />
          </div>
        </Row>
      </Col>
    </Row>
  );
};
