import React from "react";
import { NormalTexts } from "../texts/texts";
import styles from "./styles.module.css";

export const Button = ({ name, textColor, bgColor, left = 5, right = 5, onclick,children }) => {
  return (
    <div
    onClick={onclick}
      style={{ marginLeft: left, marginRight: right, backgroundColor: bgColor }}
      className={styles.ButtonsContainer}
    >
      <NormalTexts color={textColor}>{name}</NormalTexts>
      {children}
    </div>
  );
};
export const SmallButton = ({ name, textColor, bgColor, left = 5, right = 5, onclick,children }) => {
  return (
    <div
    onClick={onclick}
      style={{ marginLeft: left, marginRight: right, backgroundColor: bgColor }}
      className={styles.ButtonsContainer}
    >
      <NormalTexts color={textColor}>{name}</NormalTexts>
      {children}
    </div>
  );
};

export const ButtonPlain = ({
  name,
  textColor,
  bgColor,
  left = 0,
  right = 0,
  height,
  top,
  onclick
}) => {
  return (
    <div
    onClick={onclick}
      style={{
        marginLeft: left,
        marginRight: right,
        backgroundColor: bgColor,
        height: height,
        marginTop:top
      }}
      className={styles.ButtonsPlain}
    >
      <NormalTexts color={textColor}>{name}</NormalTexts>
    </div>
  );
};
