import React from "react";
import { SVGS } from "../../assets/svgs";
import { List } from "../benefits-list/list";
import { Col, Row } from "../containers/containers";
import { HeaderText } from "../texts/texts";
import styles from "./styles.module.css";

const list = [
  "Prints transaction receipts",
  "Accepts transactions of any value",
  "Reduces costs and risks of cash handling",
  "Accepts MasterCard, VISA and Verve cards",
  "Generates reports for reconciliation purposes",
  "Deposit Money, Withdraw money from any Bank",
  "Real time confirmation of transactions for merchants",
  "Send money to any Bank, Pay Bills, Buy Airtime",
];
const { roundedBackground, pos } = SVGS;
export const BenefitsOfWayaPay = ({ reference, addclass }) => {
  return (
    <section>
      <Row
        refe={reference}
        className={`${styles.WhyContainer} ${!addclass && "opacity"}`}
      >
        <Row className={styles.subcontainer} justify="space-between">
          <Col className={styles.banner}>
            <img src={roundedBackground} alt="" />
            <div className={styles.pos}>
              <img className={addclass ? "rot" : undefined} src={pos} alt="" />
            </div>
          </Col>
          <Col className={styles.benefitOfWayaposList}>
            <HeaderText size={48}>Benefits of WayaPOS</HeaderText>
            {list.map((el, i) => (
              <div key={i} className={addclass ? `slideUp_no_${i}` : undefined}>
                <List name={el} />
              </div>
            ))}
          </Col>
        </Row>
      </Row>
    </section>
  );
};
