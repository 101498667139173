import React, { useEffect } from "react";
import { Nav } from "../components/nav/nav";
import { Support } from "../components/customer-care/container";
import { Footer } from "../components/footer/footer";
import {Career} from "../components/career/container";


export const CareerPage = () => {

  useEffect(() => { window.scrollTo(0, 0); }, []);

  return (
    <div>
      <Nav />
      <Career />
      <Support />
      <Footer />
    </div>
  );
};
