import React from "react";
import "react-phone-number-input/style.css";
import { SVGS } from "../../assets/svgs";
import { ButtonPlain } from "../buttons/buttons";
import { Col, Row } from "../containers/containers";
import { InputText } from "../inputs/inputs";
import { CardText } from "../texts/texts";
import styles from "./styles.module.css";
import logo from '../../assets/images/newLogo.png'

const { Logo2 } = SVGS;
export const LoginHolder = () => {
  return (
    <Row className={styles.container}>
      <Col className={styles.formContainer}>
        <img src={logo} width='150px' alt="" />
        <CardText top={25} color="#3A3837" size={15}>
          CREATE YOUR WAYAPOS ACCOUNT
        </CardText>
        <Col className={styles.inputsHolder}>
          <InputText
            width={"100%"}
            label={"Email or Phone number"}
            noBorder={true}
          />
          <InputText
            width={"100%"}
            pass={true}
            type="password"
            required={true}
            label={"Password"}
            noBorder={true}
          />
          <ButtonPlain
            top={20}
            left={-7}
            textColor={"#fff"}
            name={"Log in"}
            bgColor={"#FF6700"}
          />
          <CardText top={20} size={15} color="#828282">
            Don't have an account?{" "}
            <a
              style={{ color: "#FF6700", textDecoration: "none" }}
              href="/register"
            >
              Sign up instead
            </a>
          </CardText>
          <CardText top={10} size={15}>
            <a
              style={{ color: "#FF6700", textDecoration: "none" }}
              href="/forgot-password"
            >
              Forgot your password?
            </a>
          </CardText>
        </Col>
      </Col>
    </Row>
  );
};
