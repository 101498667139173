import React from "react";
import '../animation.css'
import './styles.css';

import {SVGS} from "../../assets/svgs";
import ScrollAnimation from "react-animate-on-scroll";

const { career5 } = SVGS;

 export const Vibrant = () => (
     <div className="d-flex justify-content-center py-5 my-5">
         <div className="">
             <div className="fs-4 fw-bold text-center scale_3_0">Vibrant, Brilliant Minds</div>

             <div className="mt-5 slideUp">
                 <ScrollAnimation animateIn="animate__zoomInDown" animateOnce>
                     <img src={career5} alt=""/>
                 </ScrollAnimation>

             </div>
         </div>
     </div>
    );
