import React from "react";
import { Button } from "../buttons/buttons";
import { Col, Row } from "../containers/containers";
import { HeaderText, NormalTexts } from "../texts/texts";
import styles from "./styles.module.css";

export const Header = () => {
  return (
    <Row className={styles.MainContainer}>
      <Col className={`${styles.header}  hearderIsh`}>
        <div className={`slideUp`}>
          <HeaderText
            color="#333333"
            className={`slideUp hearderIsh`}
            size={36}
          >
            Start Accepting Payment with WayaPOS
          </HeaderText>
        </div>
        <div className={`${styles.normal} slideUp_2_0`}>
          <NormalTexts size={24} color="#6A6A6A">
            Your best partner for accepting payments from your customers and
            banking services on the go!
          </NormalTexts>
        </div>
        <Row className={styles.buttons}>
          <div className="scale">
            <Button
              onclick={() =>
                window.open(process.env.REACT_APP_REGISTER_URL, "_blank")
              }
              name={"Get Started"}
              bgColor={"#FF6700"}
            />{" "}
          </div>
          <div className="scale_2_0">
            <Button
              name={"Log in now"}
              onclick={() =>
                window.open(process.env.REACT_APP_LOGIN_URL, "_blank")
              }
              textColor={`#333333`}
            />{" "}
          </div>
        </Row>
      </Col>
      <div className={`${styles.LeftSideImage} slideLeft`}>
        <img
          className={styles.banner}
          src={require("../../assets/images/HeaderImageRIght.png")}
          alt=""
        />
      </div>
      <div className={`${styles.rightSideImage} scale_3_0`}>
        <img src={require("../../assets/images/headerModifier.png")} alt="" />
      </div>
    </Row>
  );
};
