import React from "react";
import { SVGS } from "../../assets/svgs";
import { Row } from "../containers/containers";
import { CardText } from "../texts/texts";
import styles from "./styles.module.css";

const { checked } = SVGS;
export const StartedList = ({ name, textColor, bottom }) => {
  return (
    <Row style={{ marginBottom: bottom }} className={styles.ButtonsContainer}>
      <div className={styles.imgContainer}>
        <img src={checked} style={{ marginRight: 10 }} alt={name} />
      </div>
      <CardText
        align="left"
        color={textColor}
        className={styles.startedListText}
      >
        {name}
      </CardText>
    </Row>
  );
};
