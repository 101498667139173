import React from "react";
import { IoCaretDown } from "react-icons/io5";
import { SVGS } from "../../assets/svgs";
import { icon } from "../career/jobUtils";
import "./styles.css";

const { nigeriaFlag } = SVGS;

export const Price = () => {
  return (
    <div className="py-5" style={{ background: "#E5E5E5" }}>
      <div className="py-5 d-flex justify-content-center">
        <div className="w-50">
          <div className=" pricing-title text-center">
            Simple, transparent pricing
          </div>
          <div className="my-2 text-center pricing-subtitle">
            Simple, transparent and fair pricing for your business.
          </div>
          <div className="my-2 text-center pricing-subtitle">
            <div>
              <div className="d-flex justify-content-center">
                <div className="">{icon({ src: nigeriaFlag, clss: "" })}</div>
                <div className="mx-1">Nigeria</div>
                <div className="pointer">
                  <IoCaretDown />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center pricing-bg-white py-3">
            <div className="">
              <div className="bg-orange p-1 text-white border-radius-4 my-2 text-center">
                For Local Transactions
              </div>
              <div className="d-flex">
                <div className="fw-bold fs-1">0.5%</div>
                <div className="mt-3 ms-2">per transaction</div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center pricing-bg py-5">
            Simple, transparent and fair pricing for your business.
          </div>
        </div>
      </div>
    </div>
  );
};
