import React from "react";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { FiTwitter } from "react-icons/fi";
import { IoChevronForward } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Button } from "../buttons/buttons";
import { Col, Row } from "../containers/containers";
import { HeaderText, NormalTexts } from "../texts/texts";
import styles from "./styles.module.css";
export const Support = () => {
  const navigate = useNavigate();
  return (
    <Row className={styles.CustomerSupportContainer}>
      <Row className={styles.subContainer} justify="space-between">
        <Col className={styles.eachFlex}>
          <HeaderText size={36} color="#fff">
            Customer Support
          </HeaderText>
          <NormalTexts align="left" size={18}>
            If you have any questions or want to know more about Wayapos and our
            services, check our FAQ or contact our Customer Support
          </NormalTexts>
          <Row justify="start" className={styles.iconset}>
            <div>
              <Button
                bgColor={"#FF4B01"}
                onclick={() => navigate("/contact")}
                name={`Contact Us`}
              >
                <IoChevronForward
                  color="#ffffff"
                  style={{ marginLeft: 10, color: "#fff" }}
                  size={16}
                />
              </Button>
            </div>
            <div className="overfold pointer">
              <span
                className={`${styles.urls} text-container`}
                onClick={() => window.open(process.env.REACT_APP_TWITTER_URL)}
              >
                <span className="text">
                  <FiTwitter color="#FF4B01" size={35} />
                </span>
              </span>

              <span
                className="pointer overfold"
                onClick={() => window.open(process.env.REACT_APP_FACEBOOK_URL)}
              >
                <span className="text-container">
                  <span className="text">
                    <FaFacebookF color="#FF4B01" size={32} />
                  </span>
                </span>
              </span>
              <span
                className="pointer overfold"
                onClick={() => window.open(process.env.REACT_APP_INSTAGRAM_URL)}
              >
                <span className="text-container">
                  <span className="text">
                    <AiOutlineInstagram color="#FF4B01" size={32} />
                  </span>
                </span>
              </span>
              <span
                className="pointer overfold"
                onClick={() => window.open(process.env.REACT_APP_LINKEDIN_URL)}
              >
                <span className="text-container">
                  <span className="text">
                    <FaLinkedinIn color="#FF4B01" size={32} />{" "}
                  </span>
                </span>
              </span>
              <span
                className="pointer overfold"
                onClick={() => window.open(process.env.REACT_APP_YOUTUBE_URL)}
              >
                <span className="text-container">
                  <span className="text">
                    <FaYoutube color="#FF4B01" size={32} />{" "}
                  </span>
                </span>
              </span>
            </div>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};
