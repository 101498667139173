import React from "react";
import '../animation.css'
import './styles.css';
import ScrollAnimation from 'react-animate-on-scroll';


import {SVGS} from "../../assets/svgs";
import { Container } from "@mui/material";




const { career1, career2, career3, career4 } = SVGS;

 export const Who = () => (
       <Container>
           <div className="d-xl-flex justify-content-center">

               <div  className="col-xl-6 pe-lg-3  pe-xl-5 me-xl-3 lh-lg">

                   <div className="fs-4 fw-bold">Who are we & What we do?</div>

                   <div className="mt-5">
                       <p className="py-3">
                           <ScrollAnimation animateIn="slideLeft" animateOnce>
                               WayaPay, a social affair of people from different backgrounds. Smooth originators, mystical specialists, deals hawkers, quiet as-rock client champions are some only a portion of the exceptionally cool individuals you get to work with.
                           </ScrollAnimation>
                       </p>
                   </div>
               </div>
               <div className="text-center">
                   <div className="d-md-flex">
                       <div className=" p-3 p-md-0">
                           <ScrollAnimation animateIn="animate__backInDown" animateOnce>
                               <img src={career1} alt=""/>
                           </ScrollAnimation>

                       </div>
                       <div className="ms-md-5  p-3 p-md-0">
                           <ScrollAnimation animateIn="animate__fadeInRight" animateOnce>
                               <img src={career2} alt=""/>
                           </ScrollAnimation>

                       </div>
                   </div>
               </div>
           </div>
           <div className="d-xl-flex justify-content-center scale_2_0">
               <div className="col-xl-6 pe-lg-3  pe-xl-5 me-xl-3 lh-lg">
                   <div className="mt-5 slideUp_no_8">
                       <p className="py-3">
                           In a year we have gone from nothing to an undeniable, present day and powerful internet based installments framework. Our clients depend on our tech and UX, and we are happy to see individuals partake in the consequences of our aficionado center around simplifying on the web installments and available.
                       </p>
                   </div>
               </div>
               <div className="text-center slideRight">
                   <div className="d-md-flex">
                       <div className="me-md-5 pe-5 p-md-0">
                           <img src={career3} alt=""/>
                       </div>
                       <div className=" p-3 p-md-0 ">
                           <img src={career4} alt=""/>
                       </div>
                   </div>
               </div>
           </div>
       </Container>
    );
