import React from "react";
import { IoArrowForward } from 'react-icons/io5'
import { useNavigate} from "react-router-dom";
import '../animation.css'


export const FooterGetStarted = () => {
    const navigate = useNavigate();
  return (
    <div className="row box text-center">
        <div className="get-started-bg ">
            <div className="py-4 text-orange fw-bold scale">
                Get started now to develop your business.
            </div>
            <div className="d-flex justify-content-center mb-5 pointer scale_2_0" onClick={() => navigate('/register')}>
                <div className="bg-orange text-white p-1 border-radius-4 me-2  btn-text" onClick={()=> console.log('send')} >
                    <span className="fs-6">REGISTER NOW</span>
                    <IoArrowForward  color="#ffffff" style={{marginLeft:10,color:'#fff'}} size={12}/>
                </div>
                <div className="text-orange btn-text p-1 white-button-div pointer" onClick={()=> console.log('send')} >
                    <span className="fs-6 text-decoration-underline">EXPLORE DOCS</span>
                    <IoArrowForward  color="#ffffff" style={{marginLeft:10,color:'#fff'}} size={12}/>
                </div>

            </div>
        </div>
    </div>
  );
};
