import React, { useRef, useState } from "react";
import { BenefitsOfWayaPay } from "../components/benefits-of-wayapay/BenefitsOfWayaPay";
import { Support } from "../components/customer-care/container";
import { DownloadApp } from "../components/downloadApp/container";
import { Footer } from "../components/footer/footer";
import { Frequently } from "../components/frequently-asked-questions/container";
import { GetStarted } from "../components/getStarted/container";
import { Header } from "../components/Header/header";
import { InstantSupport } from "../components/instantSupport/container";
import { BenefitsOfMerchant } from "../components/merchantBenefits/MerchantBenefit";
import { ModernSolution } from "../components/modernSolution/container";
import { Nav } from "../components/nav/nav";
import { WhyUsePos } from "../components/why-use-wayapos/WhyUsePos";

export const Home = () => {
  const why = useRef();
  const benefits = useRef();
  const started = useRef();
  const scrollRef = useRef();

  const [whyState, setwhyState] = useState(false);
  const [benefitsState, setbenefitsState] = useState(false);
  const [startedState, setstartedState] = useState(false);

  const element = (screenel, state, setState, refe, noElse = false) => {
    if (
      refe.current.getBoundingClientRect().top <= screenel / 2 &&
      refe.current.getBoundingClientRect().top > -300
    ) {
      if (!state) {
        setState(true);
      }
    } else {
      if (!noElse) {
        if (state) {
          setState(false);
        }
      }
    }
  };
  const handleLoops = () => {
    let screenel = window.screen.availHeight;
    element(screenel, whyState, setwhyState, why);
    element(screenel, benefitsState, setbenefitsState, benefits, true);
    element(screenel, startedState, setstartedState, started);
  };

  if (typeof window !== "undefined") {
    window.onscroll = () => {
      try {
        handleLoops();
      } catch (err) {}
    };
  }
  return (
    <div style={{ overflow: "hidden" }}>
      <Nav />
      <Header element={scrollRef.current} />
      <WhyUsePos addclass={whyState} reference={why} />
      <BenefitsOfWayaPay reference={benefits} addclass={benefitsState} />
      <BenefitsOfMerchant />
      <GetStarted />
      <ModernSolution />
      <DownloadApp />
      <InstantSupport />
      <Frequently />
      <Support />
      <Footer />
    </div>
  );
};
