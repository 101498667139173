import React from "react";
import "react-phone-number-input/style.css";
import { ButtonPlain } from "../buttons/buttons";
import { Col, Row } from "../containers/containers";
import { SingleInput } from "../inputs/inputs";
import { CardText, NormalTexts } from "../texts/texts";
import styles from "./styles.module.css";
// import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form-core'

export const Verify = () => {
  return (
    <Row className={styles.container}>
      <Col className={styles.formContainer}>
        <CardText bold={600} color="#3A3837" size={24}>
          Verify Your Account
        </CardText>
        <NormalTexts
          size={16}
          color="#828282"
          width={"80%"}
          top={20}
          align="center"
        >
          Please Input the OTP sent to your mobile number.{" "}
        </NormalTexts>
        <Col className={styles.inputsHolder}>
          <Row justify="space-between" className={styles.width}>
            <SingleInput />
            <SingleInput />
            <SingleInput />
            <SingleInput />
            <SingleInput />
            <SingleInput />
          </Row>
          <ButtonPlain
            top={40}
            left={-7}
            textColor={"#fff"}
            name={"Log in"}
            bgColor={"#FF6700"}
          />

          <CardText bold={600} top={20} size={15} color="#FF6700">
            Did not get OTP?
          </CardText>
        </Col>
      </Col>
    </Row>
  );
};
