import React from "react";
import wayaBank from "../../assets/images/wayaBank.png";
import wayagram from "../../assets/images/wayagram.png";
import wayaquick from "../../assets/images/wayaquick.png";
import { SVGS } from "../../assets/svgs";
import { FrequentAsked } from "../cards/card";
import { Col, Row } from "../containers/containers";
import { CardText } from "../texts/texts";
import styles from "./styles.module.css";

/* const list = [
  {
    name: "Popular Questions",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis fusce tincidunt morbi sapien. Quam id velit sed cras integer congue at ipsum. Gravida tellus tristique lectus id ridiculus sem dignissim suspendisse congue. Consectetur suspendisse pretium aliquam nunc velit sed faucibus odio feugiat. Integer ac velit egestas nisl sem eget. Libero vitae, elementum sit vulputate luctus quis tincidunt nec elementum. Arcu viverra nec at tristique vulputate sit ipsum. Mattis donec turpis hendrerit feugiat dignissim ornare in diam blandit. Enim nulla neque at mi in amet.",
  },
  {
    name: "Amount debited but order not created",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis fusce tincidunt morbi sapien. Quam id velit sed cras integer congue at ipsum. Gravida tellus tristique lectus id ridiculus sem dignissim suspendisse congue. Consectetur suspendisse pretium aliquam nunc velit sed faucibus odio feugiat. Integer ac velit egestas nisl sem eget. Libero vitae, elementum sit vulputate luctus quis tincidunt nec elementum. Arcu viverra nec at tristique vulputate sit ipsum. Mattis donec turpis hendrerit feugiat dignissim ornare in diam blandit. Enim nulla neque at mi in amet.",
  },
  {
    name: "Refund Delay",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis fusce tincidunt morbi sapien. Quam id velit sed cras integer congue at ipsum. Gravida tellus tristique lectus id ridiculus sem dignissim suspendisse congue. Consectetur suspendisse pretium aliquam nunc velit sed faucibus odio feugiat. Integer ac velit egestas nisl sem eget. Libero vitae, elementum sit vulputate luctus quis tincidunt nec elementum. Arcu viverra nec at tristique vulputate sit ipsum. Mattis donec turpis hendrerit feugiat dignissim ornare in diam blandit. Enim nulla neque at mi in amet.",
  },
  {
    name: "Dispute a payment",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis fusce tincidunt morbi sapien. Quam id velit sed cras integer congue at ipsum. Gravida tellus tristique lectus id ridiculus sem dignissim suspendisse congue. Consectetur suspendisse pretium aliquam nunc velit sed faucibus odio feugiat. Integer ac velit egestas nisl sem eget. Libero vitae, elementum sit vulputate luctus quis tincidunt nec elementum. Arcu viverra nec at tristique vulputate sit ipsum. Mattis donec turpis hendrerit feugiat dignissim ornare in diam blandit. Enim nulla neque at mi in amet.",
  },
  {
    name: "Report Fraud",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis fusce tincidunt morbi sapien. Quam id velit sed cras integer congue at ipsum. Gravida tellus tristique lectus id ridiculus sem dignissim suspendisse congue. Consectetur suspendisse pretium aliquam nunc velit sed faucibus odio feugiat. Integer ac velit egestas nisl sem eget. Libero vitae, elementum sit vulputate luctus quis tincidunt nec elementum. Arcu viverra nec at tristique vulputate sit ipsum. Mattis donec turpis hendrerit feugiat dignissim ornare in diam blandit. Enim nulla neque at mi in amet.",
  },
];
 */
const { bag } = SVGS;
export const Frequently = () => {
  const {
    REACT_APP_WAYABANK_URL,
    REACT_APP_WAYAQUICK_URL,
    REACT_APP_WAYAGRAM_URL,
  } = process.env;
  return (
    <Col className={styles.WhyContainer}>
      <Row className={styles.secondSub}>
        <div className={styles.imgContainer}>
          <img src={bag} alt="" style={{ marginRight: 30 }} />
        </div>
        <CardText size={20}>
          Explore other Businesses by WayaLinks Limited
        </CardText>
      </Row>
      <Row className={styles.cardHolder} justify="space-between">
        <FrequentAsked
          stylez={{ paddingTop: 0, height: "auto" }}
          link={REACT_APP_WAYABANK_URL}
          buttonName={"Explore WayaBank"}
          icon={wayaBank}
          text={
            "Discover the best way to to carry your financial transactions. Free internet banking and bills payment "
          }
        />
        <FrequentAsked
          link={REACT_APP_WAYAGRAM_URL}
          buttonName={"Explore WayaGram"}
          icon={wayagram}
          text={
            "Discover beautiful places, people, vendors and clients. Chat and call clients, friends and family"
          }
        />
        <FrequentAsked
          link={REACT_APP_WAYAQUICK_URL}
          buttonName={"Explore WayaQuick"}
          icon={wayaquick}
          text={
            "Accept payment on your website or mobile application via card, USSD, bank, wallet, PayAttitude."
          }
        />
      </Row>
    </Col>
  );
};
