export const data = [
  {
    label: "About Us",
    link: "https://www.wayalinks.com/about",
    newTab: true,
  },
  {
    label: "Terms & Conditions",
    link: "/terms",
  },
  {
    label: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    label: "Contact Us",
    link: "/contact",
  },
  {
    label: "FAQs",
    link: "/faq",
  },
  {
    label: "Support",
    link: "https://wayabank.zohodesk.com/portal/en/home",
    newTab: true,
  },
];
