import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SVGS } from "../../assets/svgs";
import { Button } from "../buttons/buttons";
import "./styles.css";
//const { Logo } = SVGS;
import Logo  from '../../assets/images/posLogo.png'

export const Nav = () => {
  const navigate = useNavigate();

  const [isNavExpanded, setIsNavExpanded] = useState(false);
  return (
    <nav className="nav-container" style={{background: '#FF6700'}}>
      <div onClick={() => navigate("/")} className="brand-name w-25">
        <img src={Logo} width='150px' alt="" />
      </div>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        {/* icon from heroicons.com */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={`${
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }`}
      >
        <ul>
          <li>
            <Button
              onclick={() => navigate("/pricing")}
              bgColor={`#FF6700`}
              name={`Pricing`}
            />
          </li>
          <li>
            <Button
              onclick={() => navigate("/contact")}
              bgColor={`#FF6700`}
              name={`Contact`}
            />
          </li>
          <li>
            <Button
              onclick={() =>
                window.location.assign(process.env.REACT_APP_LOGIN_URL)
              }
              bgColor={`#FF6700`}
              name={`Login`}
            />
          </li>
          <li>
            <Button
              onclick={() =>
                window.location.assign(process.env.REACT_APP_REGISTER_URL)
              }
              textColor={`#FF6700`}
              name={`Register`}
            />
          </li>
        </ul>
      </div>
    </nav>
  );
};
