import Logo from './svgs/logo.svg';
import time from './svgs/time.svg';
import headset from './svgs/headset.svg';
import light from './svgs/light.svg';
import roundedBackground from './svgs/roundedBackground.svg';
import checked from './svgs/checked.svg';
import add from './svgs/add.svg';
import remove from './svgs/remove.svg';
import bag from './svgs/bag.svg';
import Logo2 from './svgs/logo2.svg';
import home from './svgs/home.svg';
import mapNav from './svgs/mapNav.svg';
import map from './svgs/map.svg';
import polygon from './svgs/polygon.svg';
import customer from './svgs/customer.svg';
import partner from './svgs/partner.svg';
import merchant from './svgs/merchant.svg';
import client from './svgs/client.svg';
import lightening from './svgs/lightening.svg';
import career1 from './svgs/career1.svg';
import career2 from './svgs/career2.svg';
import career3 from './svgs/career3.svg';
import career4 from './svgs/career4.svg';
import career5 from './svgs/career5.svg';
import career6 from './svgs/career6.svg';
import career7 from './svgs/career7.svg';
import menu from './svgs/menu.svg';
import phone from './svgs/phone.svg';
import careerBg from './svgs/careerBg.svg';
import getStartedBg from './svgs/getStartedBg.svg';
import pos from './svgs/pos.svg';
import benefits1 from './svgs/benefits1.svg';
import benefits2 from './svgs/benefits2.svg';
import benefits3 from './svgs/benefits3.svg';
import solution1 from './svgs/solution1.svg';
import solution2 from './svgs/solution2.svg';
import solution3 from './svgs/solution3.svg';
import orangeBg from './svgs/orangeBg.svg';
import googlePlay from './svgs/googlePlay.svg';
import appStore from './svgs/appStore.svg';
import downloadCircles from './svgs/downloadCircles.svg';
import handPhone from './svgs/handPhone.svg';
import handClick from './svgs/handClick.svg';
import nigeriaFlag from './svgs/nigeriaFlag.svg';






export const SVGS = {
    Logo,
    time,
    light,
    headset,
    roundedBackground,
    orangeBg,
    checked,
    add,
    bag,
    remove,
    Logo2,
    home,
    mapNav,
    map,
    polygon,
    customer,
    partner,
    merchant,
    client,
    lightening,
    career1,
    career2,
    career3,
    career4,
    career5,
    career6,
    career7,
    menu,
    phone,
    careerBg,
    getStartedBg,
    pos,
    benefits1,
    benefits2,
    benefits3,
    solution1,
    solution2,
    solution3,
    googlePlay,
    appStore,
    downloadCircles,
    handPhone,
    handClick,
    nigeriaFlag
};
