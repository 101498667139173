import React, { useState } from "react";
import { SVGS } from "../../assets/svgs";
import { QuestionList } from "../each-question/list";
import { InstantSupport } from "../instantSupport/container";

const { customer, partner, merchant, client } = SVGS;
const cards = {
  customer: (
    <div>
      <span className="icon-holder p-2">
        <img src={customer} alt="" />
      </span>
      <span className="mt-2 px-2 fw-bold">Customer</span>
    </div>
  ),
  partner: (
    <div>
      <span className="icon-holder p-2">
        <img src={partner} alt="" />
      </span>
      <span className="mt-2 px-2 fw-bold">Partner</span>
    </div>
  ),
  merchant: (
    <div>
      <span className="icon-holder p-2">
        <img src={merchant} alt="" />
      </span>
      <span className="mt-2 px-2 fw-bold">Merchant</span>
    </div>
  ),
  client: (
    <div>
      <span className="icon-holder p-2">
        <img src={client} alt="" />
      </span>
      <span className="mt-2 px-2 fw-bold">Prospective Client</span>
    </div>
  ),
};

export const Faqs = () => {
  const [show, setShow] = useState(-1);
  const [card, setCard] = useState("");

  const list = [
    {
      name: "How do I set up an account with Wayapos",
      body: (
        <div>
          <span>It's really easy! You can</span>
          <span
            className="pointer text-decoration-underline px-1 text-orange"
            onClick={() =>
              window.open(process.env.REACT_APP_REGISTER_URL, "_blank")
            }
          >
            register
          </span>
          <span>
            via the web or Go to Google Play or App store and download Wayapos
            Mobile. Then, follow the instructions to register your account.
          </span>
          <p>
            After setting up your account, you can request for your POS terminal
            right on the application and it will be delivered to your doorstep.
            Thereafter you can start processing your transactions.
          </p>
        </div>
      ),
    },
    {
      name: "What documents are required to complete my registration?",
      body: (
        <div>
          <ul>
            <li>Your Bank Verification Number (BVN)</li>
            <li>
              {" "}
              A utility or another bill dated no later than three months prior
              to merchant registration as proof of your address.
            </li>
            <li>
              {" "}
              Copy of your national ID card, driver’s license, or international
              passport.
            </li>
            <li> 1 passport photo.</li>
          </ul>
        </div>
      ),
    },
    {
      name: "Can I proceed with registration if I do not have the Corporate Affairs Commission (CAC) or business name documents?",
      body: (
        <div>
          <span>Yes, you can proceed to</span>
          <span
            onClick={() =>
              window.open(process.env.REACT_APP_REGISTER_URL, "_blank")
            }
            className="pointer mx-1 text-decoration-underline text-orange"
          >
            register
          </span>
          <span>as a wayapos merchant without this document.</span>
        </div>
      ),
    },
    {
      name: "Is there a limit on the maximum transaction amount for a merchant?",
      body: "Yes, there are transaction limits. Transaction limits apply to the different types of accounts.",
    },
    {
      name: "Who bears the transaction charge?",
      body: "By default, you bear the transaction charges. You can change this on your dashboard anytime to your preference.",
    },
  ];
  const list2 = [
    {
      name: "What do I do when I receive an error on my POS?",
      body: (
        <div className="justify-content-start d-flex">
          <div>
            <p>The following errors are possible to occur on your POS:</p>
            <div className="d-flex justify-content-start">
              <ul>
                <li>Communication error</li>
                <li>SSL Connection failure</li>
                <li>Wave lost</li>
                <li>Try Again</li>
              </ul>
            </div>
            <div>
              <p>
                To troubleshoot these errors, please turn off the POS for about
                one minute, then turn it back on and try again.
              </p>
              If you still have the same problem, please send a screenshot of
              the error message to the WhatsApp group created by the Wayapos
              Team for businesses. or log a complain on the wayapos app, while
              the appropriate team will reach out to you and provide help to
              solve the error.
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "What do I do when a transaction is made via the POS but I do not see the funds credited in my account?",
      body: (
        <div>
          Please report this immediately in the Support tab or log a complain in
          the Wayapos App. Remember to include the transaction number and a
          screenshot of the transaction receipt.
        </div>
      ),
    },
    {
      name: "How much do I pay to get a WAYAPOS Terminal?",
      body: (
        <div>
          To get the WayaPOS, you pay N20,000.
        </div>
      ),
    },
    {
      name: "How long does it take to get a WayaPOS terminal?",
      body: (
        <div>
          After you make a request, you can get the WayaPOS terminal within 2-3 Business working days
        </div>
      ),
    },

    {
      name: "What cards can I accept on my WayaPOS terminal?",
      body: (
        <div>
          Your POS terminal is compatible with all cards.
        </div>
      ),
    },

    {
      name: "Can I accept transfers with my WayaPOS terminal?",
      body: (
        <div>
          Yes, you can.
        </div>
      ),
    },

    {
      name: "Who can I ask questions about my WayaPOS terminal?",
      body: (
        <div>
          Our wonderful customer support team members are always there for you.
        </div>
      ),
    },

    {
      name: "What if I want to return WayaPOS?",
      body: (
        <div>
          You can return your POS terminal to Wayapos office or conatct your sales. And the deposit for Caution Fee will be returned to your wayabank account within 14 days after the acceptance no damage.
        </div>
      ),
    },



    {
      name: "How long does it take for a reversal to happen?",
      body: "Reversals on the system happen instantly to the cardholder’s account.",
    },
    {
      name: "What do I do if my POS shows that the reversal is approved but cardholder’s account is still debited?",
      body: (
        <div>
          <p>
            Please request for the cardholder to resolve this with their bank.
          </p>
          Please ensure that the reversal process completes before pulling out
          the user’s card as this could lead to the POS not being able to read
          the card or its associated account details.
        </div>
      ),
    },
  ];

  const userTemp = (
    <div className="d-flex flex-wrap justify-content-center align-items-center my-4">
      <div className="text-center">
        <button
          className="card-button d-flex flex-row p-3 md-3 m-2"
          onClick={() => setCard("customer")}
        >
          {cards.customer}
        </button>
        <button
          className="card-button d-flex flex-row p-3 md-3 m-2"
          onClick={() => setCard("partner")}
        >
          {cards.partner}
        </button>
      </div>
      <div className="text-center">
        <button
          className="card-button d-flex flex-row p-3 md-3 m-2"
          onClick={() => setCard("merchant")}
        >
          {cards.merchant}
        </button>
        <button
          className="card-button d-flex flex-row p-3 md-3 m-2"
          onClick={() => setCard("client")}
        >
          {cards.client}
        </button>
      </div>
    </div>
  );
  const faqTemp = (
    <div>
      <div className="d-flex justify-content-center">
        <div className="col-md-6">
          <div className="my-3 d-flex justify-content-start faq-title">
            Account Creation
          </div>
          <div className=" justify-content-center align-items-center">
            {list.map((el, i) => (
              <QuestionList
                textColor={"#fff"}
                key={i}
                name={el.name}
                body={el.body}
                show={show}
                index={i}
                click={() => setShow(show === i ? -1 : i)}
              />
            ))}
          </div>

          <div className="my-3 d-flex justify-content-start faq-title">
            POS Terminal
          </div>
          <div className=" justify-content-center align-items-center">
            {list2.map((el, i) => (
              <QuestionList
                textColor={"#fff"}
                key={i + 40}
                name={el.name}
                body={el.body}
                show={show}
                index={i + 40}
                click={() => setShow(show === i + 40 ? -1 : i + 40)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <div className="row box ">
        <div className="py-4 bg-orange text-white text-center faq-header mb-5">
          Frequently asked Questions
        </div>
        <div className="row my-5">{faqTemp}</div>

        {/* <FooterGetStarted /> */}
      </div>
      <InstantSupport />
    </div>
  );
};
