import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row } from "../containers/containers";
import { NormalTexts } from "../texts/texts";
import { data } from "./data";
import styles from "./styles.module.css";

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <Row className={`${styles.NavContainer} ${styles.footer}`}>
      <Row className={styles.subcontainer}>
        {data.map(({ label, link, newTab }) => (
          <>
            <span className={styles.linkContainer}>
              {newTab ? (
                <a
                  className={styles.links}
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {label}
                </a>
              ) : (
                <Link to={link} className={styles.links}>
                  {label}
                </Link>
              )}
            </span>
          </>
        ))}
      </Row>
      <Row className={styles.subcontainer} justify="space-between">
        <Row>
          <NormalTexts color="#273043">
            Copyright © Wayapos{" "}
            <span className="mx-1">{new Date().getFullYear()}</span> All rights
            reserved
          </NormalTexts>
        </Row>
      </Row>
    </Row>
  );
};
