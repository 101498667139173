import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./assets/btn-animations.css";
import { ForgotPin } from "./components/forgotPin/container";
import { NewPassword } from "./components/newPassword/container";
import { NewPin } from "./components/newPin/container";
import { Reset } from "./components/reset/container";
import { Verify } from "./components/verify/container";
import { CareerPage } from "./pages/career";
import { Contact } from "./pages/contact";
import { Create } from "./pages/create";
import { Faq } from "./pages/faq";
import { Forgot } from "./pages/forgot";
import { FraudRules } from "./pages/fraud";
import { Home } from "./pages/home";
import { Login } from "./pages/login";
import { Pricing } from "./pages/pricing";
import { PrivacyPolicy } from "./pages/privacy";
import { Terms } from "./pages/terms";
import SupportButton from "./components/buttons/supportButton";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/verify" exact element={<Verify />} />
          <Route path="/register" exact element={<Create />} />
          <Route path="/forgot-password" exact element={<Forgot />} />
          <Route path="/forgot-pin" exact element={<ForgotPin />} />
          <Route path="/new-password" exact element={<NewPassword />} />
          <Route path="/reset-pin" exact element={<NewPin />} />
          <Route path="/reset" exact element={<Reset />} />
          <Route path="/faq" exact element={<Faq />} />
          <Route path="/terms" exact element={<Terms />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/fraud" exact element={<FraudRules />} />
          <Route path="/career" exact element={<CareerPage />} />
          <Route path="/pricing" exact element={<Pricing />} />
        </Routes>
        <SupportButton/>
      </div>
    </BrowserRouter>
  );
}

export default App;
