import React from "react";
import { SVGS } from "../../assets/svgs";
import { Col } from "../containers/containers";
import { CardText } from "../texts/texts";
import styles from "./styles.module.css";

const { add, remove } = SVGS;
export const QuestionList = ({
  name,
  textColor,
  bottom,
  body,
  click,
  show,
  index,
}) => {
  return (
    <Col className={styles.overall}>
      <div
        style={{ marginBottom: bottom }}
        className={styles.ButtonsContainer}
        onClick={click}
      >
        <CardText size={22} align="left" color={textColor}>
          <span className="faq-question`">{name}</span>
        </CardText>
        <div style={{ cursor: "pointer" }}>
          <img
            src={show === index ? remove : add}
            style={{ marginRight: 10, width: 15 }}
            alt=""
          />
        </div>
      </div>
      {show === index && (
        <Col className={styles.text}>
          <div className="faq-answer">{body}</div>
        </Col>
      )}
    </Col>
  );
};
