import React from "react";
import { Button } from "../buttons/buttons";
import { Col, Row } from "../containers/containers";
import { IconWrapper } from "../iconWrapper/wrapper";
import { CardText, NormalTexts } from "../texts/texts";
import styles from "./styles.module.css";

export const WhyPosCard = ({ icon, text }) => {
  return (
    <Row className={styles.CardContainer}>
      <Col className={styles.subcontainer}>
        <IconWrapper>
          <img src={icon} alt={text}  width='120px'/>
        </IconWrapper>
        <div>
          <CardText align="center" top={10}>
            {text}
          </CardText>
        </div>
      </Col>
    </Row>
  );
};

export const FrequentAsked = ({ icon, text, buttonName, link }) => {
  return (
    <Row className={styles.freg}>
      <Col className={styles.subcontainer} justify="space-between">
        <img src={icon} alt="" style={{ width: "120px" }} />
        <div>
          <NormalTexts
            align="center"
            size={13}
            color="#828282"
            top={10}
            bottom={10}
          >
            {text}
          </NormalTexts>
        </div>
        {/*<a style={{ textDecoration: "none" }} href={link}>*/}
        <Button
          onclick={() => window.open(link, "_blank")}
          name={buttonName}
          bgColor={"#FF6700"}
        />
        {/*</a>*/}
      </Col>
    </Row>
  );
};
