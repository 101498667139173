import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import { SVGS } from "../../assets/svgs";
import { ButtonPlain } from "../buttons/buttons";
import { Col, Row } from "../containers/containers";
import { CheckboxalL, InputSelect, InputText } from "../inputs/inputs";
import { CardText, NormalTexts } from "../texts/texts";
import styles from "./styles.module.css";
import logo from '../../assets/images/newLogo.png'

const { Logo2 } = SVGS;
export const CreateHolder = () => {
  const navigate = useNavigate();

  const [business, setBusiness] = useState(true);
  const [step, setStep] = useState(1);

  const handleSteps = (step) => {
    setStep(step);
  };
  return (
    <Row className={styles.container}>
      <Col className={styles.formContainer}>
        <img src={logo} width='150px' alt="" />
        <CardText top={25} color="#3A3837" size={15}>
          CREATE YOUR WAYAPOS ACCOUNT
        </CardText>
        {step === 1 && (
          <Row className={styles.selection}>
            <Col
              onclick={() => setBusiness(true)}
              className={`${styles.selected} ${business && styles.active}`}
            >
              Business
            </Col>
            <Col
              onclick={() => setBusiness(false)}
              className={`${styles.selected} ${!business && styles.active}`}
            >
              Personal
            </Col>
          </Row>
        )}
        {business && step === 1 && (
          <Col className={styles.inputsHolder}>
            <InputText width={"100%"} required={true} label={"Business Name"} />
            <InputText
              width={"100%"}
              required={true}
              label={"Business Email"}
            />

            <Col className={styles.width}>
              <Row className={styles.width}>
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="NG"
                  style={{ background: "none", width: "100%", marginTop: 10 }}
                  inputComponent={() => (
                    <input
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.15)",
                        height: 30,
                        borderRadius: 5,
                        width: "calc(100% - 55px)",
                      }}
                    />
                  )}
                  // value={phone}
                  // disabled={!editState}
                  // onChange={setPhone}
                  // className={classnames({ 'is-invalid': errors['phone'] })}
                  // error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                />
              </Row>
            </Col>
            <InputSelect
              top={15}
              width={"100%"}
              required={true}
              label={"Business Type"}
            />
            <ButtonPlain
              top={40}
              onclick={() => handleSteps(2)}
              left={-7}
              textColor={"#fff"}
              name={"Next"}
              bgColor={"#FF6700"}
            />
          </Col>
        )}

        {/* FOR PERSONAL ACCOUNT */}
        {!business && step === 1 && (
          <Col className={styles.inputsHolder}>
            <InputText width={"100%"} required={true} label={"Business Name"} />
            <InputText
              width={"100%"}
              required={true}
              label={"Business Email"}
            />
            <InputText
              width={"100%"}
              required={true}
              label={"Personal Email Address"}
            />
            <Col className={styles.width}>
              <Row className={styles.width}>
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="NG"
                  style={{ background: "none", width: "100%", marginTop: 10 }}
                  inputComponent={() => (
                    <input
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.15)",
                        height: 30,
                        borderRadius: 5,
                        width: "calc(100% - 55px)",
                      }}
                    />
                  )}
                  // value={phone}
                  // disabled={!editState}
                  // onChange={setPhone}
                  // className={classnames({ 'is-invalid': errors['phone'] })}
                  // error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                />
              </Row>
            </Col>
            <InputText
              width={"100%"}
              required={true}
              label={"Official Address"}
            />

            <InputSelect
              top={15}
              width={"100%"}
              required={true}
              label={"state"}
            />
            <InputSelect
              top={15}
              width={"100%"}
              required={true}
              label={"city"}
            />

            <ButtonPlain
              top={40}
              left={-7}
              onclick={() => handleSteps(2)}
              textColor={"#fff"}
              name={"Next"}
              bgColor={"#FF6700"}
            />
          </Col>
        )}
        {step === 2 && (
          <Col className={styles.inputsHolder}>
            <InputText width={"100%"} label={"Referal Code"} />
            <InputText
              width={"100%"}
              pass={true}
              type="password"
              required={true}
              label={"Create Password"}
            />
            <InputText
              pass={true}
              width={"100%"}
              type="password"
              under={true}
              underColor={"#FF6700"}
              underText={
                "Password must contain at least 8 characters, an uppercase letter,a lowercase letter, a number and a special character."
              }
              required={true}
              label={"Comfirm Password"}
            />
            <Row className={styles.checkbox}>
              <CheckboxalL />
              <NormalTexts color="#828282" align="left" size={12}>
                <span>
                  I consent to the collection and processing of my personal data
                  in line with data regulations as described in the
                </span>
                <span
                  className="pointer text-decoration-underline ps-2 text-orange"
                  onClick={() =>
                    window.open(
                      "https://www.wayapaychat.com/terms-of-use",
                      "_blank"
                    )
                  }
                >
                  Wayapos’s privacy policy.
                </span>
              </NormalTexts>
            </Row>
            <ButtonPlain
              onclick={() => navigate("/verify")}
              top={40}
              left={-7}
              textColor={"#fff"}
              name={"Create account"}
              bgColor={"#FF6700"}
            />
            <NormalTexts size={10} color="##989898" top={20} align="left">
              <div className="">
                <span>
                  By clicking the “Create your account” button, you agree to
                </span>
                <span
                  className="pointer text-decoration-underline ps-2 text-orange"
                  onClick={() =>
                    window.open(
                      "https://www.wayapaychat.com/terms-of-use",
                      "_blank"
                    )
                  }
                >
                  Wayapos’s terms of acceptable use.
                </span>
              </div>
            </NormalTexts>
          </Col>
        )}
        <NormalTexts top={20} size={15} color="#828282">
          Have an account?{" "}
          <a style={{ color: "#0becc7", textDecoration: "none" }} href="/login">
            Sign In
          </a>
        </NormalTexts>
      </Col>
    </Row>
  );
};
