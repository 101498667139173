import React from "react";
import { SVGS } from "../../assets/svgs";
//import { Benefits } from "../career/Benefits";
import { icon } from "../career/jobUtils";

const { benefits1, benefits2, benefits3 } = SVGS;

export const benefits = [
  {
    icon: icon({ src: benefits1, clss: "content" }),
    name: "Trusted and Reliable Service",
    body: `
    Wayapos is a CBN licensed application platform. We offer low fees and a hassle-free way to carry out an array of services for your customers.
    `,
  },
  {
    icon: icon({ src: benefits2, clss: "content" }),
    name: "Instant Transaction Settlement",
    body: `
    Receive funds into your settlement account instantly and offer transfer services to your customers in real-time. No waiting for end-of-day reconciliation.
    `,
  },
  {
    icon: icon({ src: benefits3, clss: "content" }),
    name: "Business Intelligence and Growth Insights",
    body: `
    As your partner, your growth is our growth. We offer a powerful dashboard to view all your business transactions and helps you understand your business better.
    `,
  },
];
export const BenefitsOfMerchant = () => {
  return <></>;
  /* <Benefits
      title="Benefits of WayaPOS"
      clss="ps-md-5 ms-md-5"
      arr={benefits}
      animationInside="animate__jackInTheBox"
      cardClss="border-shadow-bottom"
    /> */
};
