import React from "react";
import { Col, Row } from "../containers/containers";
import { StartedList } from "../started-list/list";
import { HeaderText } from "../texts/texts";
import styles from "./styles.module.css";

const list = [
  "Register to be a wayapos user",
  "Request for terminal POS",
  "Receive your Terminal POS device!",
  "Start performing Transactions",
];
export const GetStarted = ({ reference }) => {
  return (
    <Row className={styles.WhyContainer}>
      <div className={styles.mainContainer}>
        <HeaderText size={36}>How to get started?</HeaderText>
        <div className={styles.subContainer}>
          <Col className={styles.containerDimensions}>
            <div className="align-content-center">
              {list.map((el, i) => (
                <StartedList key={i} name={el} />
              ))}
            </div>
          </Col>
          <Col refe={reference} className={styles.banner}>
            <div className={styles.pos}>
              <img
                className="forScroll"
                src={require("../../assets/images/started.png")}
                style={{ borderRadius: 20 }}
                height={250}
                alt=""
              />
            </div>
          </Col>
        </div>
      </div>
    </Row>
  );
};
