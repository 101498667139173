import React from "react";
import "react-phone-number-input/style.css";
import { SVGS } from "../../assets/svgs";
import { ButtonPlain } from "../buttons/buttons";
import { Col, Row } from "../containers/containers";
import { InputText } from "../inputs/inputs";
import { CardText, NormalTexts } from "../texts/texts";
import styles from "./styles.module.css";
// import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form-core'

const { Logo2 } = SVGS;
export const NewPassword = () => {
  return (
    <Row className={styles.container}>
      <Col className={styles.formContainer}>
        <img src={Logo2} alt="" />
        <CardText top={25} color="#3A3837" size={24}>
          Reset Password
        </CardText>
        <NormalTexts top={16} size={16} color="#737171">
          Fill in your new password
        </NormalTexts>
        <Col className={styles.inputsHolder}>
          <InputText
            width={"100%"}
            pass={true}
            type="password"
            placeholder={"Password"}
            noBorder={true}
          />
          <InputText
            width={"100%"}
            pass={true}
            type="password"
            placeholder={"Comfirm Password"}
            noBorder={true}
          />
          <ButtonPlain
            top={20}
            left={-7}
            textColor={"#fff"}
            name={"Reset Password"}
            bgColor={"#FF6700"}
          />

          <CardText top={20} size={15} color="#828282">
            Don't have an account?{" "}
            <a style={{ color: "#FF6700", textDecoration: "none" }} href="#">
              Sign up instead
            </a>
          </CardText>
        </Col>
      </Col>
    </Row>
  );
};
