import React, { useEffect } from "react";
import { Nav } from "../components/nav/nav";
import { Support } from "../components/customer-care/container";
import { Footer } from "../components/footer/footer";
import { Conditions } from "../components/conditons/container";

export const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav />
      <Conditions />

      <Support />
      <Footer />
    </div>
  );
};
