import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { IoCaretForward } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { SVGS } from "../../assets/svgs";
import { icon } from "../career/jobUtils";
import { Row } from "../containers/containers";
import styles from "./styles.module.css";
const { handClick } = SVGS;

export const InstantSupport = () => {
  const navigate = useNavigate();
  return (
    <Row className=" pt-3 download-bg">
      <div className={styles.subContainer}>
        <div className={`col ${styles.instantSupportStory}`}>
          <div className="download-title">
            <ScrollAnimation animateIn="animate__slideInRight">
              Instant support when you need it
            </ScrollAnimation>
          </div>
          <div className="download-subtitle my-4">
            <ScrollAnimation animateIn="animate__slideInRight" delay={5}>
              Send us a message at support@wayapos.ng to get assistance with all
              your business enquiries. Our support team is always available to
              answer your questions.
            </ScrollAnimation>
          </div>
          <button
            onClick={() => navigate("/contact")}
            className="btn bg-orange text-white my-3"
          >
            Contact Us
            <IoCaretForward />
          </button>
        </div>
        <div className="col align-items-center">
          <Row>
            <div className="pulse">{icon({ src: handClick, clss: "" })}</div>
          </Row>
        </div>
      </div>
    </Row>
  );
};
