import React from "react";
import { SVGS } from "../../assets/svgs";
//import { Benefits } from "../career/Benefits";
import { icon } from "../career/jobUtils";
import { Row } from "../containers/containers";
import "./styles.module.css";

const { solution1, solution2, solution3 } = SVGS;

export const benefits = [
  {
    icon: icon({ src: solution1, clss: "modern-solution" }),
    name: "Web App",
    body: `
  Manage every aspect of your business with the Wayapos web app and get easy access to all your finances and transaction.    `,
  },
  {
    icon: icon({ src: solution2, clss: "modern-solution" }),
    name: "POS Terminal",
    body: `
  Accept payments from your customers with our POS terminals which are secure and reliable for all your card transactions.    `,
  },
  {
    icon: icon({ src: solution3, clss: "modern-solution" }),
    name: "Mobile App",
    body: `
  Manage every aspect of your business with the Wayapos mobile app and get easy access to all your finances and transaction.    `,
  },
];
export const ModernSolution = () => {
  return (
    <Row className="orange-bg pt-3">
      {/* <Benefits
        title={
          <div className="solution-title ps-5">
            <div>Modern solutions </div>
            <div>to connect to your business</div>
          </div>
        }
        clss="ps-md-5 ms-md-5730px"
        arr={benefits}
        animationInside="animate__jackInTheBox"
        cardClss="modern-solution-card"
        subtitle="Unlock more opportunities  to finance your business."
        titleClss="text-white"
        subtitleClss="text-white my-4 ps-5"
        cardTitleCss="solution-card-title"
        cardTextCss="solution-card-text"
        customClassName="solution"
      /> */}
    </Row>
  );
};
