import React from "react";
import styles from "./styles.module.css";

export const Row = ({ children, className, onclick, refe }) => {
  return (
    <div ref={refe} onClick={onclick} className={`${styles.row} ${className}`}>
      {children}
    </div>
  );
};

export const Col = ({
  align = "center",
  children,
  justify = "center",
  className,
  onclick,
  refe,
}) => {
  return (
    <div
      ref={refe}
      onClick={onclick}
      style={{ justifyContent: justify, alignItems: align }}
      className={`${styles.col} ${className}`}
    >
      {children}
    </div>
  );
};
