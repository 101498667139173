import React from "react";
import { SVGS } from "../../assets/svgs";
import "./styles.css";

const { lightening } = SVGS;

export const Fraud = () => {
  const icon = (
    <div>
      <span className="icon-holder p-3">
        <img src={lightening} alt="" />
      </span>
    </div>
  );
  const rules = [
    {
      icon,
      name: `Multiple (3 times) transactions from Same IP within one hour - Either with same email or different emails, Same device signature or different device signatures, same pan or different pan. `,
      body: `All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number). After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review `,
    },
    {
      icon,
      name: `Blocking based on transaction responses. Responses with high fraud scores e.g Insufficient balance, pick up card, do not honor, transaction not permitted to card holder. (Depending on fraud score could be blocked on first attempt if accumulated score for last 3 transactions is greater than 50) `,
      body: `All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number).  After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review `,
    },
    {
      icon,
      name: `Email Check for multiple transactions (3x) within one hour - Either with same pan, different pans, same device signatures or different device signatures, same IP or different IP addresses `,
      body: `All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number).  After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review `,
    },
    {
      icon,
      name: `Device Signature block for multiple transactions (3x) within one hour - Either with same pan, different pans,  same device signatures or different device signatures, same IP or different IP address `,
      body: `All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number).  After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review `,
    },
    {
      icon,
      name: `Card Pan block for multiple transactions (3x) - Same pan used 3x within one hour - Either with same pan, different pans, same device signatures or different device signatures, same IP or different IP address `,
      body: `All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number).  After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review `,
    },
    {
      icon,
      name: `Insufficient Balance decline response with subsequent attempted lower transaction amount. `,
      body: `All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number).  After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review `,
    },
    {
      icon,
      name: `Insufficient Balance for multiple transactions (3x attempts within an hour) `,
      body: `All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number).  After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review `,
    },
    {
      icon,
      name: `Multiple (5 times) transactions from Same IP, Same email, Same device signature, Same Pan successful or failed within a Period of 1 hour `,
      body: `All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number).  After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review `,
    },
    {
      icon,
      name: `3 attempts within 30 minutes on same email, card PAN or device ID where  at least one reason for failure is insufficient funds `,
      body: `1 hour suspension of device. Email should not be suspended. Display warning message to cardholder. Report to Admin Notification Page for further review `,
    },
    {
      icon,
      name: `Further attempts within 1 hour on same email, card PAN or device ID where at least one more reason for failure is insufficient funds `,
      body: `3-hour suspension of device, card PAN & Email. Display warning message to cardholder. Report to Admin Notification Page for further review `,
    },
    {
      icon,
      name: `3 attempts within 30 minutes on same email, card PAN or device ID where at least one reason for failure is Stolen Card/Pick Up Card `,
      body: `Blocking of device ID and Email. Display warning message to cardholder.  Report to Admin Notification Page for further review   `,
    },
    {
      icon,
      name: `3 attempts within 30 minutes on same email, card PAN or device ID where at least one reason for failure is Fraud Risk `,
      body: `Suspension of device ID, Card PAN & Email for 15 minutes. Display warning message to cardholder.  Report to Admin Notification Page for further review   `,
    },
    {
      icon,
      name: `Where there are attempts to perform transactions on a merchants account by customers with disposable email address. `,
      body: `Transaction is declined and email address blocked. Report to Admin Notification Page for further review `,
    },
  ];

  const conditionsTemp = rules.map((item) => (
    <div key={item.name} className="py-3">
      <div>{item.icon}</div>
      <div className="pb-3 fw-bold mt-2">{item.name}</div>
      <div className="pb-4">{item.body}</div>
    </div>
  ));

  const screen = (
    <div className="px-5 ps-md-5 ms-md-5">
      <div className="mt-5">{conditionsTemp}</div>
    </div>
  );
  const xs = (
    <div className="row d-lg-none ">
      <div className="pe-5">{screen}</div>
    </div>
  );
  const lg = (
    <div className="row d-none d-lg-block d-xl-none px-5 ">
      <div className="d-md-flex">{screen}</div>
    </div>
  );
  const xl = (
    <div className="row d-none d-xl-flex px-75 ">
      <div className="d-flex justify-content-center">
        <div className="w-75">{screen}</div>
      </div>
    </div>
  );
  return (
    <div className="mb-3">
      <div className="py-5 bg-orange text-white text-center fw-bold mb-5 fs-4">
        Fraud Rules
      </div>
      {xs}
      {lg}
      {xl}
    </div>
  );
};
