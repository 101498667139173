import React, {useRef} from "react";
import './styles.css';


export const Conditions = () => {
    const Age = useRef(null);
    const Intellectual = useRef(null);
    const License = useRef(null);
    const Who = useRef(null);
    const Warranty = useRef(null);
    const Limitation = useRef(null);
    const Indemnification = useRef(null);
    const Breaches = useRef(null);
    const Cookies = useRef(null);
    const Governing = useRef(null);
    const General = useRef(null);
    const Sub = useRef(null);
    const Prohibited = useRef(null);
    const Suppliers = useRef(null);
    const Individual = useRef(null);
    const Aggrement = useRef(null);
    const Whereas = useRef(null);
    const Difination = useRef(null);
    const Commencement = useRef(null);
    const AgentObligation = useRef(null);
    const WayaObligation = useRef(null);
    const AggregatorObligation = useRef(null);
    const MutualObligation = useRef(null);
    const PosCollectionFee = useRef(null);
    const PosTransFee = useRef(null);
    const Taxes = useRef(null);
    const PosReturn = useRef(null);
    const PosTarget = useRef(null);
    const PosRefund = useRef(null);
    const Bonus = useRef(null);
    const Settlement = useRef(null);
    const ChargeBack = useRef(null);
    const Indemnity = useRef(null);
    const Termination = useRef(null);
    const Assignment = useRef(null);
    const Representation = useRef(null);
    const Waiver = useRef(null);
    const Dispute = useRef(null);
    const Force = useRef(null);
    const Schedule1 = useRef(null);
    const Schedule2 = useRef(null);




    const conditions = [
        {
            link: Age,
            name: ` Age Restriction`,
            body: ` In order to use the payment services, you must be over 18.`
        },
        {
            link: Intellectual,
            name: `  Intellectual Property`,
            body: `        Unless otherwise stated, Flutterwave and/or its licensors own the intellectual property rights and materials on the website subject to the license below. We do not grant you any right, license, title or interest to any of our intellectual Property rights which you may or may not have access to. You agree to take such actions including any legal or official document or other documents that may be needed to further affirm our intellectual property rights.`
        },
        {
            link: License,
            name: ` License to Use Our Website`,
            body: ` We grant you a non-assignable, non-exclusive and revocable license to use the software provided as part of our services in the manner permitted by these Terms. This license grant includes all updates, upgrades, new versions and replacement software for you to use in connection with our services.

        The services are protected by copyright, trademark, and other laws of both Nigeria and foreign countries. Nothing in this Term gives you a right to use the Flutterwave name or any of Flutterwave’s trademarks, logos, domain names, and other distinctive brand features. All right, title and interest in and to the services are and will remain the exclusive property of Flutterwave and its licensors.

        If you do not comply with all the provisions, then you will be liable for all resulting damages suffered by you, Flutterwave and all third parties. Unless otherwise provided by applicable law, you agree not to alter, re-design, reproduce, adapt, display, distribute, translate, disassemble, reverse engineer, or otherwise attempt to create any source code that is derived from the software.

        Any feedback, comments, or suggestions you may provide to us and our services is entirely voluntary and we will be free to use such feedback, comments or suggestion as we see fit without any obligation to you.`
        },
        {
            link: Who,
            name: ` Who May Use Our Services?`,
            body: `        You may use the Services only if you agree to form a binding contract with Flutterwave and are not a person barred from receiving services under the laws of the applicable jurisdiction. If you are accepting these Terms and using the Services on behalf of a company, business, or organization, you represent and warrant that you are authorized to do so.`
        },
        {
            link: Warranty,
            name: `Warranty Disclaimer`,
            body: `FLUTTERWAVE WILL ALWAYS ENSURE THAT THE WEBSITE IS AVAILABLE AT ALL TIMES AND BUG FREE. HOWEVER, IT IS USED AT YOUR OWN RISK.

        WE PROVIDE ALL MATERIALS “AS IS” WITH NO WARRANTY, EXPRESS OR IMPLIED, OF ANY KIND. WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS, INCLUDING ANY IMPLIED WARRANTY OR CONDITION OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AVAILABILITY, SECURITY, TITLE, AND NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, RAVEPAY MAKES NO WARRANTY THAT OUR WEBSITE AND SERVICES WILL MEET YOUR REQUIREMENTS OR THAT OUR WEBSITE WILL REMAIN FREE FROM ANY INTERRUPTION, BUGS, INNACCURACIES, AND ERROR FREE.

        YOUR USE OF OUR SERVICES ARE AT YOUR OWN RISK AND YOU ALONE WILL BE RESPONSIBLE FOR ANY DAMAGE THAT RESULTS IN LOSS OF DATA OR DAMAGE TO YOUR COMPUTER SYSTEM. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN OBTAINED BY YOU FROM OUR WEBSITE OR OUR SERVICES WILL CREATE ANY WARRANTY OR CONDITION NOT EXPRESSLY STATED.`
        },
        {
            link: Limitation,
            name: ` Limitation of Liability`,
            body: `YOU AGREE TO THE LIMITATION LIABILITY CLAUSE TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW: FLUTTERWAVE WILL IN NO WAY BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL PUNITIVE, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES OR ANY DAMAGES INCLUDING DAMAGES RESULTING FROM REVENUE LOSS, PROFIT LOSS, USE, DATA, GOODWILL , BUSINESS INTERRUPTION OR ANY OTHER INTANGIBLE LOSSES (WHETHER FLUTTERWAVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR NOT) ARISING OUT OF FLUTTERWAVE’S WEBSITE OR SERVICES (INCLUDING, WITHOUT LIMITATION TO INABILITY TO USE, OR ARISING FROM THE RUSULT OF USE OF FLUTTERWAVE’S WEBSITE OR SERVICES) WHETHER SUCH DAMAGES ARE BASED ON WARRANTY, TORT, CONTRACT, STATUTE OR ANY OTHER LEGAL THEORY.

        SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF CERTAIN WARRANTIES OR LIMITATIONS ON THE SCOPE AND DURATION OF SUCH WARRANTIES, SO THE ABOVE DISCLAIMERS MAY NOT APPLY TO YOU IN THEIR ENTIRITIES, BUT WILL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.`
        },
        {
            link: Indemnification,
            name: `Indemnification`,
            body: `        You hereby indemnify Flutterwave and undertake to keep Flutterwave indemnified against any losses, damages, costs, liabilities and expenses (including without limitation to reasonable legal fees) arising out of any breach by you of any provision of these Terms, or arising out of any claim that you have breached any provision of these Terms.`
        },
        {
            link: Breaches,
            name: `Breaches of these Terms`,
            body: `        Without prejudice to Flutterwave’s other rights under these Terms, if you breach these Terms in any way, Flutterwave may take such action as Flutterwave deems appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceedings against you.`
        },
        {
            link: Cookies,
            name: `Cookies`,
            body: ` Like many other websites, we use cookies to identify you as a user and to customize and improve our services. A cookie is a small data file that is transferred to your computer or mobile device. It enables us to remember your account log-in information, IP addresses, web traffic, number of times you visit, date and time of visits.

        Some browsers may automatically accept cookies while some can be modified to decline cookies or alert you when a website wants to place a cookie on your computer. If you do choose to disable cookies, it may limit your ability to use our website.`
        },
        {
            link: Governing,
            name: `Governing Law`,
            body: `        These Terms shall be interpreted and governed in accordance with the Laws of the State of California and you submit to the non-exclusive jurisdiction of the State and Federal Courts located in California for the resolution of any dispute.`
        },
        {
            link: General,
            name: `General`,
            body: `We may revise these Terms from time to time. The changes will not be retroactive, and the most current version of the Terms which will always be on www.flutterwave.com/stg will govern our relationship with you. We will also try to notify you of any material changes which could be done via email associated with your account or service notification. By continuing to use our services after the changes become effective, you agree to be bound by the revised Terms.

        In the event that any of these Terms is held to be invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in full effect. Flutterwave’s failure to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision. `
        },
        {
            link: Aggrement,
            name: ` Wayabank Agent POS Service Agreement`,
            body: ``
        },

    ];
    const aggre = [
        {   
            heading: `Effective Date: 14th August 2023` ,
            body: `PLEASE READ ALL THE CONTENT OF THIS DOCUMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION BEFORE ACCEPTING THIS AGREEMENT. IF YOU DO NOT AGREE WITH ANYTHING IN THIS AGREEMENT, OR IF YOU CANNOT UNDERSTAND THE MEANING OF A PROVISION ACCURATELY, PLEASE DO NOT CLICK “AGREE & NEXT” OR PERFORM ANY FOLLOW-UP ACTIONS.
            BY CLICKING “AGREE & NEXT”, OR OTHERWISE ACCEPTING THESE TERMS, YOU ARE SIGNIFYING THAT YOU HAVE READ AND UNDERSTOOD THE TERMS, AND AGREE TO BE BOUND BY THESE TERMS, AND ALL FUTURE MODIFICATIONS TO THESE TERMS IN THIS AGREEMENT.
            THIS AGENT POS SERVICE AGREEMENT (“Agreement”) is entered among Waya Multilinks Technology LTD, a private limited company incorporated under the laws of the Federal Republic of Nigeria together with its parent, subsidiaries, successors, assignees and affiliates, (hereinafter referred to as “Wayabank or Wayapos”), ___________, a private limited company incorporated under the laws of the Federal Republic of Nigeria, (hereinafter referred to as “the Aggregator”) and ___________, the holder of National ID/International Passport/BVN No. ________________ (hereinafter referred to as “the Agent”);
            Wayapos, the Aggregator, and the Agent are hereinafter jointly referred to as “Parties” and each a “Party”.
            `,
            sub:[],
            sub2: []

        },
        {   
            link: Whereas,
            heading: `WHEREAS` ,
            body: '',
            sub:[
                `A. Wayapos powered by Wayabank is a Micro-Finance Bank (MFB) licensed by the Central Bank of Nigeria (CBN) to provide mobile payment solutions and value-added services to individuals and businesses.`,
                `B. Wayapos, through partnership with a payment terminal service provider (PTSP) licensed by the Central Bank of Nigeria (CBN), develop and deploy a payment solutions application that will run on NIBSS certified android point of sale terminals (the “POS Terminals”), and provide payment terminals and value-added services to a wide range of customers including institutions, corporate bodies, and individuals.`,
                `C. The Aggregator is authorized by Wayapos to promote the deployment and service of the POS Terminal in the designated region and assist to improve the on-boarding experience.`,
                `E. The Agent requires Wayapos to provide it with a Point of Sale (POS) Terminal for the acceptance of electronic cards for the receipt of payment from their customers for agency banking and bill payment services.`,
                `F. The Parties now wish to enter into this Agreement to set forth the terms and conditions which will govern the relationship in the use of the terminals.`,
                `NOW THEREFORE, in consideration of the mutual covenants and promises hereinafter set forth, the Parties agree as follows:`
            ],
            sub2: []
        },
        {
            link: Difination,
            heading: `1.	DEFINITIONS` ,
            body:`In this Agreement:`,
            sub:[
                `1.1 “Card” means an electronic card either debit, credit, prepaid or a combination (of the two or three variants in one) issued by Nigerian banks;`,
                `1.2 “Cardholder” means the owner of the electronic card presented for use on the POS terminal;`,
                `1.3 “Hotlist” means the list containing the information on missing, stolen, expired, invalid, canceled or disabled Electronic Debit, Credit or Prepaid card;`,
                `1.4 “Agent” means the business or principal which is provided with a POS terminal for accepting payments by means of card;`,
                `1.5 “Issuing bank” is the cardholders’ bank which has agreed to credit the acquiring bank on behalf of its Agent for successful transaction;`,
                `1.6 “PIN” means a personal identification number inputted or formulated by the cardholder for use on POS;`,
                `1.7 “Point of Sale Terminal” or “POS” means the machine used for Electronic Funds Transfer transaction;`,
                `1.8 “Wayapos Agent Settlement Account” means the bank account provided by the Agent for receiving settlement for all successful processed transactions.`,
                `1.19 “Transaction Fees” means the fees payable by the Agent every time an electronic cash value is successfully completed using an electronic payment card;`,
                `1.15 “Transfer” means the process by which electronic cash value is transferred from an electronic payment card to another electronic card or account and the terms “Transferring” and “Transferred” shall be construed in like manner.`,
                
            ],
            sub2: []
        },
        {   
            link: Commencement,
            heading: `2.	COMMENCEMENT & TERM` ,
            body: `This Agreement shall commence on the Effective Date and shall remain in force for a period of 1 (one) years (Term); and shall automatically renew and extend for an additional 1 (one) year term commencing at the conclusion of the current term unless otherwise terminated in accordance with Clause 17 of this Agreement.`,
            sub:[],
            sub2: []
        },
        {
            link: AgentObligation,
            heading: `3.	AGENT’S OBLIGATIONS` ,
            body: `The Agent shall:`,
            sub:[
                `3.1 The Agent shall ensure proper and safe upkeep of the POS terminal.`,
                `3.2 The Agent shall not discriminate against any Cardholder who presents his/her card for agency banking and bill payment services except in cases of suspicion of fraud.`,
                `3.3 The Agent shall bear responsibility and liability for all local, state and federal government taxes due on its transactions.`,
                `3.4 The Agent shall honor all valid cards regardless of the issuing bank.`,
                `3.5 The Agent shall ensure the POS display the amount to be authorised on the screen requesting PIN entry, so that the customer can see the amount being paid before authorization.`,
                `3.6 The Agent shall not misuse, damage, alter, and attempt to alter or tamper with the POS.`,
                `3.7 The Agent shall not disclose to any third party the fees charged by the Wayapos on their POS transactions unless required by law or regulatory agencies and the Agent is obliged to inform Wayapos of any such disclosure.`,
                `3.8 For transactions of N100,000.00 (One Hundred Thousand Naira) and above, the Agent shall ensure that cardholder presents a valid form of identification such as Driver’s license, International passport or other forms of Identification accepted by law before accepting a cardholder’s payment card.`,
                `3.19 The Agent shall in addition to 3.8 above, request, document the cardholder’s name; means of identification; telephone number and address and make photocopies of the cardholder’s mean of identification for all transactions of N100,000.00 (One Hundred Thousand Naira) and above.`,
                `3.15 The Agent shall ensure that cardholder’s signs both receipts generated by POS terminal and shall compare with the signature behind the cardholder’s card. If the signature does not correspond, the Agent should not provide service to the cardholder and promptly notify Wayapos immediately.`,
                `3.15 The Agent should reject any unsigned electronic payment card for POS transactions.`,
                `3.11 The Agent report all suspicious transactions to Wayapos not later than 24 hours or the next working day.`,
                `3.22 The Agent shall check card security features prior to completing any transaction. Such checks shall include but not be limited to comparing card number on the card with the card number on the POS receipt or voucher.`,
                `3.13 The Agent shall compare the last four digits of the card number and name on the card with number printed by the POS terminal if the number differs, the Agent shall contact Wayapos immediately and should not release services to the cardholder.`,
                `3.19 The Agent shall ensure that card transactions are performed in a secure environment ensuring that the “card never leaves sight of cardholder” rule is strictly observed. Non-compliance with this will trigger the immediate cancellation of the Agreement and responsibility is passed to the Agent in case of fraud on the card(s).`,
                `3.15 In the event of a valid fraudulent transaction claim, the Agent would be charged 105% of the value of the claim if the Agent is found to be negligent in the area of enforcing cardholder identification at the point of receiving the cardholder’s payment card to be used on the POS terminal.`,
                `3.22 In the event of a valid fraudulent transaction claim, if the Agent is found to have connived with the perpetrator(s), the Agent would be charged 200% of the value of the claim, reported to the law enforcement agencies and/or blacklisted from POS transactions by regulatory authorities.`,
                `3.18 The Agent agrees to be responsible for all the actions of the principal and all its employees including fraudulent acts or omissions.`,
                `3.119 The Agent has consented to discretionary use of transaction and other Agent information obtained by Wayapos.`,
                `3.20 Wayapos may freeze the Agent’s Wayapos Agent and/or Wayapos account in an event where a fraudulent POS transaction is reported on the POS terminal deployed to the Agent’s location.`,
                `3.21 Agent must have an office, shop, Kiosk or umbrella stand before Wayapos will issue the POS Device. In other words, Wayapos will not issue a POS device if agent do not a physical business location.`,

            ],
            sub2: []
        },
        {
            link: WayaObligation,
            heading: `4.	WAYAPOS’S OBLIGATIONS` ,
            body: `Wayapos shall:`,
            sub:[
                `4.1 provide the POS Terminals to PTSP for the development of the payment solutions application;`,
                `4.2 develop the payment solutions application for the POS Terminals;`,
                `4.3 be responsible for the software maintenance of the POS Terminals;`,
                `4.4 facilitate the acquisition of a terminal ID from the acquiring bank and advice PTSP in batches for configuration of the POS Terminals;`,
                `4.5 be responsible for delivering the terminal POS device to agent business location within 2 to 3 business working days.`,
                `4.5 offer other support services for efficient and effective operation of this Agreement; and`,
                `4.6 be responsible for providing technical support to terminal users at the expense of the Agent.`,
                `4.7 ensure all POS terminals procured by Wayapos are compliant with minimum POS specifications.`,
                `4.8 cover the costs of repairs and replacements of parts for malfunction and damage of terminals caused by Wayapos.`
                
            ],
            sub2: []
        },
        {
            link: AggregatorObligation,
            heading: `5.	AGGREGATOR’S OBLIGATIONS` ,
            body: `The Aggregator shall:`,
            sub:[
                `5.1 Promote the deployment and service of POS Terminals to qualified Agents in accordance to existing laws and regulations in the Federal Republic of Nigeria.`,
                `5.2 Conduct the 1st round review of the KYC (“Know Your Customer”) documents at Wayapos’s request, which include but not limited to application form, identification documents and proof of address and ensure its completeness, accuracy and quality.`,
                `5.3 Promptly notify Wayapos upon the conclusion of the review mentioned in Clause 5.2 and assist in on-boarding process of the Agent in accordance with the compliance policies and standard operating procedure of Wayapos.`,
                `5.4 Assist in visitation of the prospective Agents and execution of the agreements.`,
                `5.5 Responsible to manage any dispute arise during the promotion and on-boarding stage between the Aggregator and the Agent.`,
            ],
            sub2: []
        },
        {
            link: MutualObligation,
            heading: `6.	MUTUAL OBLIGATIONS` ,
            body: `Each party to this Agreement shall:`,
            sub:[
                `6.1 Perform the obligations contained in this Agreement for their collective benefits.`,
                `6.2 Cooperate with the other Party by making available the relevant information, documentation and technical personnel in a timely fashion to implement all necessary measures for the services.`,
                `6.3 Use all reasonable measures to maintain confidentiality and prevent fraud.`
            ],
            sub2: []
        },
        {
            link: PosCollectionFee,
            heading: `7.	POINT OF SALE (POS) COLLECTION FEE` ,
            body: '',
            sub:[
                `7.1 Before a POS device is issued, Agent agrees to pay a processing fee of NGN20,000, where NGN10,000 is for caution fee, NGN10,000 is for delivery and NGN0.0 for Insurance Fee for the period of 1 year but thereafter 1 year Agent will be paying the sum of NGN1,500 yearly for Insurance Fee. The caution fee serves as a security deposit and is refundable upon the return of the POS device in a good working condition, subject to the conditions outlined in this Agreement, reference to clause 10 and clause 12.`,
                `7.2 Agent may opt to pay the processing fee in two equal installments of NGN10,000 each. The first installment is due upon the issuance of the POS device, and the second installment is due one month after the device is issued. Failure to make installment payments within the specified timeframe will result in auto debit of NGN10,000 on your Wayabank Account.`,
                `7.3. Agent are only allowed to login into the Wayapos app and make payment during the terminal request process. And can also pay directly into the Wayapos Terminal Request account stated herein;
                `
            ],
            sub2: [
                `Account Number: 20152619135`,
                `Bank Name: Waya Microfinance Bank`,
                `Account Name: Waya Multilinks Technology LTD`
            ]
        },
        {
            link: PosTransFee,
            heading: `8.	POINT OF SALE (POS) TRANSACTION FEE` ,
            body: '',
            sub:[
                `8.1 The Parties agree that the transaction fee referred to in Schedule 1 below shall be the fee payable by the Agent to Wayapos for the provision of services.`,
                `8.2 The Parties agree that the fees can be modified to suit business requirements and be mutually agreed after prior notification.`,
                `8.3 The transaction fee shall be paid to Wayapos; this will be deducted from the Agent’s transaction amount before the remaining will be settled into Agent’s default settlement account.`,
                `8.4 The transaction fee payable are as follows:`,
                `8.4.1 The Agent shall pay of all Verve cards POS transactions`,
                `8.4.2 The Agent shall pay of all Visa cards POS transactions`,
                `8.4.3 The Agent shall pay of all MasterCard POS transactions`,
                `8.5 The Agent shall under no circumstance charge a different price, surcharge a cardholder or otherwise discriminate against any member of the public who chooses to pay with a card or by other electronic means.
                `
            ],
            sub2: []
        },
        {
            link: Taxes,
            heading: `9.	TAXES & DUTIES` ,
            body: `9.1 The Parties agree that the taxes & duties referred to in Schedule 2 below shall be the payable by the Agent to Wayapos for the provision of services.`,
            sub:[],
            sub2: []
        },
        {
            link: PosTarget,
            heading: `10. POINT OF SALE (POS) TARGETS` ,
            body: ``,
            sub:[
                `10.1 Agent is expected to perform a minimum transaction volume of N30,000 with transaction count of 4 daily on business working days excluding Saturday and Sunday (Cashout from POS or Withdrawal from Wayabank are inclusive on this target).`,
                `10.2 If an Agent fails to meet the target specified above consistently, after 60 days (2 months) counting from terminal POS issuance date, his or her POS will be retrieved by the aggregators that issued the POS or any other aggregator assigned by the company.`,
                `10.3. If Agent failed to transact at all for the period of 4 days without any technical constraint from Wayapos, his or her POS will be retrieved by the aggregators that issued the POS or any other aggregator assigned by the company.
                `
            ],
            sub2: []
        },
        {
            link: PosReturn,
            heading: `11. POINT OF SALE (POS) RETURN` ,
            body:`11.1 If the Agent decides to discontinue the use of the POS device, the Agent must return it to Wayapos within 14 days (2 weeks) from the date of termination or cancellation of this Agreement. `,
            sub:[],
            sub2: []
        },
        {
            link: PosRefund,
            heading: `12. POINT OF SALE (POS) REFUND` ,
            body: `12.1 After 3 Months of Agent onboarding, wayapos will inspect and verify the condition of the POS device. Upon satisfactory inspection, the caution fee will be refunded to the Agent. While delivery and Insurance Fee are non-refundable.`,
            sub:[],
            sub2: []
        },
        {
            link: Bonus,
            heading: `13. BONUS/COMMISSIONS TO AGGREGATORS/AGENTS` ,
            body: '',
            sub:[
                `13.1 Aggregator will get 10% of the Wayapos Revenue generated anytime the Agent on-boarded by the Aggregator perform a transaction of any category Waya generates a revenue.`,
                `13.2 Agent/Aggregator will get N500 bonus anytime the Agent on-boarded by the Aggregator performs an outbound transfer up to 5 times. 
                `
            ],
            sub2: []
        },
        {   Link: Settlement,
            heading: `14.	SETTLEMENT REVERSAL` ,
            body: ``,
            sub:[
                `14.1 All successful transactions would be settled to the Agent’s Wayapos default settlement account.`,
                `14.2 Agent Settlement will take place instantly if Agent set Wayabank account as their preferred means of receiving settlement, else if Agent set other bank as their preferred means of receiving settlement, settlement will take place within the close of business day on a T+1 basis, (where T is day of the transaction) barring unforeseen events which are beyond the control of Wayapos.`,
                `14.3 Settlement of the Agent transaction would be done any day of the week including Saturday and Sunday if Agent set Wayabank Account as their preferred settlement account. Else, if Agent set other bank as their preferred settlement account, Settlement would only be done on a working day (i.e. Monday to Friday except on national or state declared holidays). Settlement of the Agent transaction done on weekends and public holidays will take place at the close of the next working day.`,
                `14.4 The Agent would keep all cardholder’s name, cardholder’s address, cardholder’s telephone, Agent issued receipts, and receipt/invoicing log for a minimum of 180 days (one hundred and eighty days) and the documents should be presented to Wayapos within 24 hours of request. The Agent who fails to provide the above within the stipulated timeline will not be entitled to receive their settled funds correspondingly.`,
                `14.5 The Agent shall report to Wayapos if it erroneously receives POS transaction funds not transacted on the POS terminal(s) deployed to them.`,
            ],
            sub2: []
        },
        {
            link: ChargeBack,
            heading: `15.	CHARGEBACK` ,
            body: ``,
            sub:[
                `15.1 Wayapos Chargeback.`,
                `15.1.1 Wayapos Chargeback means the chargeback request made by the Cardholder to Wayapos through the Agent for unsuccessful or declined POS transactions.`,
                `15.1.2 In the event of an unsuccessful or declined POS transaction, the Agent may initiate a chargeback on behalf of the Cardholder on the Wayapos Support Channel after obtaining the Cardholder’s authorization.`,
                `15.1.3 The Agent shall provide the information required by Wayapos on behalf of the Cardholder to prove the POS transactions is unsuccessful or declined. It is at Wayapos’s sole discretion to decide whether to accept the request or not.`,
                `15.1.4 If the Cardholder’s chargeback request to Wayapos via the Agent is successful, Wayapos will credit the amount directly to the Cardholder’s bank account.`,
                `15.1.5 The Agent should clearly inform the Cardholder that if the Cardholder has applied for a Chargeback with Wayapos, the Cardholder shall not apply for a Chargeback with the issuing bank for the same POS transaction. The Agent hereby indemnify Wayapos of any loss incurred due to the Cardholder’s application of a Chargeback with the issuing bank upon applied for a Chargeback with Wayapos. Agent shall indemnify Wayapos through its Wayapos Agent account and if the balance in his or her Wayapos Agent account is insufficient to cover Wayapos’s losses, the Agent hereby authorises Wayapos to deduct the corresponding losses from his or her Wayapos account.`,
                `15.1.6 The Agent undertakes to provide accurate information in relation to the Wayapos Chargeback on behalf of the Cardholder. The Agent shall indemnify Wayapos for any loss arising from incorrect information provided. Agent shall indemnify Wayapos through its Wayapos Agent account and if the balance in his or her Wayapos Agent account is insufficient to cover Wayapos’s losses, the Agent hereby authorises Wayapos to deduct the corresponding losses from his or her Wayapos account.`,
                `15.1.7 The Agent hereby undertakes not to engage in any malpractice with the objective of defrauding Cardholders or Wayapos.`,
                `15.2 Chargeback Request to the Issuing Bank`,
                `15.2.1 Chargeback Request to the issuing bank means the chargeback made by the Cardholder to the Issuing Bank regarding the POS transactions that may be either unsuccessful or declined.`,
                `15.2.2 Wayapos is entitled to debit the Agent for the value of the amount where Chargeback Request to the Issuing Bank has been submitted to Wayapos for the sucessful POS transaction. The Agent would receive his or her funds if satisfactorily documentary evidence is provided to oppose the request.`,
                `15.2.3 In case of unsuccessful or declined POS transactions, the Cardholder may submit a Chargeback request through the Agent to Wayapos or the issuing bank, however, the chargeback request can only be submitted to one of the parties.`,
                `15.3 The Agent shall not under any circumstance refund the Cardholder in cash or by providing the requested services for unsuccessful or declined POS transactions.`,
                `15.4 Wayapos shall not be held liable for any losses may incur from a chargeback request through the Agent for unsuccessful or declined POS transactions.`,
            ],
            sub2: []
        },
        {
            link: Indemnity,
            heading: `16.	INDEMNITY` ,
            body: ``,
            sub:[
                `16.1 The Agent and the Aggregator shall agree to defend, indemnify, and hold Wayapos, its officers, directors, employees, agents, licensees, and suppliers, harmless from and against any claims, actions or demands, liabilities and settlements including without limitation, reasonable legal and accounting fees, resulting from, or alleged to result from, your violation of this Agreement.`
            ],
            sub2: []
        },
        {
            link: Termination,
            heading: `17.	TERMINATION` ,
            body: '',
            sub:[
                `17.1 Where Wayapos wishes to terminate this Agreement as a result of breach of any of the provisions of this Agreement by the Agent or the Aggregator, Wayapos shall give the Agent or the Aggregator at least fourteen (14) days’ written notice of such breach and requiring the Agent or the Aggregator to rectify the breach.`,
                `17.2 Where the Agent or the Aggregator fails to rectify the breach within the stipulated period, the Wayapos may terminate the Agreement forthwith. Such termination shall be without prejudice to any claim Wayapos may have against the Agent or the Aggregator arising from the alleged breach.`,
                `17.3 Termination of this Agreement for any cause shall not affect any rights or obligations of the Parties under the Agreement, which had already accrued at the time of termination.`,
                `17.4 Each party may terminate this Agreement upon fourteen (14) days’ written notice to the other party. If no party exercises its right to terminate, then the Agent and the Aggregator hereby agrees and undertakes not to take any injunctive step or seek an injunction to restrain the possession of the POS at any time whether or not the parties are in court or dispute. Wayapos reserves the right to terminate this Agreement immediately irrespective of the above provision.`,
            ],
            sub2: []
        },
        {
            link: Assignment,
            heading: `18.	ASSIGNMENT` ,
            body:`18.1 No Party may assign any of their rights, obligations or responsibilities under this Agreement without the prior written consent of the other Party and in absence of such consent any such assignment shall be null and void.`,
            sub:[],
            sub2: []
        },
        {
            link: Representation,
            heading: `19.	REPRESENTATIONS AND WARRANTIES` ,
            body:'',
            sub:[
                `19.1 Each Party represents and warrants, as of the Effective Date, to the other Party that:`,
                `19.1.1 it has full power and authority to enter into, and perform its obligations under, this Agreement;`,
                `19.1.2 there are no conditions, events, occurrences or other circumstances that might materially adversely affect its ability to carry out its obligations under this Agreement;`,
                `19.1.3 its execution and performance of this Agreement will not contravene any provision of, or constitute a default under, any law, its constitutional documents or other agreement to which it is a party; and`,
                `19.1.4 it has the financial capability, technical competence and good standing to perform its obligations under this Agreement.`,
            ],
            sub2: []
        },
        {
            link: Waiver,
            heading: `20.	WAIVER` ,
            body: `20.1 In the event of any dispute or litigation by way of court action, arbitration or otherwise Wayapos shall without prejudice to its right have the right to repossess the POS, and the Agent and the Aggregator hereby agrees and undertakes not to take any injunctive step or seek an injunction to restrain the possession of the POS at any time whether or not the parties are in court or dispute.`,
            sub:[],
            sub2: []
        },
        {
            link: Governing,
            heading: `21.	GOVERNING LAW AND DISPUTE RESOLUTION` ,
            body: ``,
            sub:[
                `21.1 Wayapos shall not be involved in any disputes that may arise between the Agent and any of its Customers.`,
                `21.2 Where any dispute, claim or controversy arises between the Parties (hereinafter referred to as the “Dispute”), concerning this Agreement, the Parties shall attempt to resolve the dispute by negotiation. For the avoidance of doubt, this will entail that one Party invites the other Party in writing to meet, to resolve the dispute within thirty (30) days from the date of the written invitation.`,
                `21.3 If the Dispute has not been resolved by such negotiation, the Parties shall submit the Dispute to the Lagos Chamber of Commerce International Arbitration Centre (LACIAC) (which rules are deemed to be incorporated by reference into this clause).`,
                `21.4 The arbitration shall be held in Lagos, Nigeria and shall be conducted in English.`,
                `21.5 The validity, construction and interpretation of this Agreement and the rights and duties of the Parties hereto shall be governed by the laws of the Federal Republic of Nigeria and the Parties hereby submit to the jurisdiction of the Courts of the Federal Republic of Nigeria.`,
                
            ],
            sub2: []
        },
        {
            link: Force,
            heading: `22.	FORCE MAJEURE` ,
            body: ``,
            sub:[
                `22.1 No Party shall be liable for loss or damage arising out of any delay or failure of performance caused by circumstances beyond its control including but not limited to earthquakes, flood, hurricane, act of God or of public enemies, war, national emergencies, invasion, insurrection, riots, strikes, picketing, boycott or interruption of services rendered by any utility or communication service provider nor shall any delay or failure of performance due to said causes be deemed a breach or a default in the performance of this Agreement.`,
                `22.2 The party affected by the force majeure event shall immediately within five (5) days of it becoming aware of the event notify the other party of the occurrence.`,
                `22.3 Where the force majeure persists for a period longer than sixty (60) days, the non-defaulting party shall have the right to terminate this Agreement forthwith.`,
                `22.4 Upon such termination, each Party shall be relieved from its respective obligations, except for obligations for payment of monetary sums which arose prior to the event of Force Majeure.`,
            ],
            sub2: []
        },
        {
            link: Schedule1,
            heading: `SCHEDULE ONE: POINT OF SALE (POS) TRANSACTION FEE` ,
            body: `0.4% (VAT inclusive) capped at N100 (One Hundred Naira) per single POS transaction.
            The above-preset transaction fee may be subject to adjustment from time to time upon prompt notification from Wayapos to the Aggregator and the Agent.
            `,
            sub:[],
            sub2: []
        },
        {
            link: Schedule2,
            heading: `SCHEDULE TWO: TAXES AND DUTIES` ,
            body: `Value-added Tax (VAT) and Stamp Duty (SD) are included in the Transaction Fee as stipulated in Schedule One.
            The above-preset taxes & duties may be subject to adjustment from time to time upon prompt notification from Wayapos to the Aggregator and the Agent.
            `,
            sub:[],
            sub2: []
        },
       
    ]
    const links = [
       /* {name: 'Age Restriction', to: Age},
        {name: 'Intellectual Property', to: Intellectual},
        {name: 'License to Use Our Website', to: License},
        {name: 'Who May Use Our Services', to: Who},
        {name: 'Warranty Disclaimer', to: Warranty},
        {name: 'Limitation of Liability', to: Limitation},
        {name: 'Indemnification', to: Indemnification},
        {name: 'Breaches of these Terms', to: Breaches},
        {name: 'Cookies', to: Cookies},
        {name: 'Governing Law', to: Governing},
        {name: 'General', to: General},
        {name: 'Sub-Merchants Requiring Pre-Approval', to: Sub},
        {name: 'Prohibited Sub-Merchants', to: Prohibited},
        {name: 'Suppliers and Vendors', to: Suppliers},
        {name: 'Individual Users',  to: Individual},
        {name: 'Wayabank Agent POS Service Agreement',  to: Aggrement},
        */
        {name: 'WHEREAS',  to: Whereas},
        {name: 'DEFINITIONS',  to: Difination },
        {name: 'COMMENCEMENT & TERM',  to: Commencement },
        {name: 'AGENT’S OBLIGATIONS',  to: AgentObligation },
        {name: 'WAYAPOS’S OBLIGATIONS',  to: WayaObligation },
        {name: 'AGGREGATOR’S OBLIGATIONS',  to: AggregatorObligation },
        {name: 'MUTUAL OBLIGATIONS',  to: MutualObligation },
        {name: 'POINT OF SALE (POS) COLLECTION FEE',  to: PosCollectionFee },
        {name: 'POINT OF SALE (POS) TRANSACTION FEE',  to: PosTransFee},
        {name: 'TAXES & DUTIES',  to: Taxes },
        {name: 'POINT OF SALE (POS) TARGETS',  to: PosTarget },
        {name: 'POINT OF SALE (POS) RETURN',  to: PosReturn },
        {name: 'POINT OF SALE (POS) REFUND',  to:  PosRefund},
        {name: 'BONUS/COMMISSIONS TO AGGREGATORS/AGENTS',  to: Bonus },
        {name: 'SETTLEMENT REVERSAL',  to: Settlement },
        {name: 'CHARGEBACK',  to: ChargeBack},
        {name: 'INDEMNITY',  to: Indemnity},
        {name: 'TERMINATION',  to: Termination},
        {name: 'ASSIGNMENT',  to: Assignment},
        {name: 'REPRESENTATIONS AND WARRANTIES',  to: Representation},
        {name: 'WAIVER',  to: Waiver},
        {name: 'GOVERNING LAW AND DISPUTE RESOLUTION',  to: Governing},
        {name: 'FORCE MAJEURE',  to: Force},
        {name: 'SCHEDULE ONE: POINT OF SALE (POS) TRANSACTION FEE',  to: Schedule1},
        {name: 'SCHEDULE TWO: TAXES AND DUTIES',  to: Schedule2},

    ];

    const scrollToElement = (ref) => ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    const conditionsTemp = conditions.map((item) => (
        <div key={item.name} className="py-3" ref={item.link}>
            <div className="fs-4 fw-bold">{item.name}</div>
            <div className="">{item.body}</div>
        </div>
    ));
    const aggreementsTemp = aggre.map((item) => (
        <div key={item.heading} className="py-3" ref={item.link}>
            <div className="fs-4 fw-bold mb-2">{item.heading}</div>
            <div className="">{item.body}</div>
            {item?.sub?.length > 0? 
                item?.sub?.map(e => 
                    <div className="">{e}</div>

                ): ''
        }
        {item?.sub2?.length > 0? 
                item?.sub2?.map(e => 
                    <div className=" mt-3 fw-bold ml-10">{e}</div>

                ): ''
        }
        </div>
    ));
    const linktsTemp = (
        <div className="">
            {
                links.map((item) =>(
                    <div className="py-1 pointer link" key={item.name} onClick={() => scrollToElement(item.to)}>
                        {item.name}
                    </div>
                ))
            }
        </div>
    );
    const xs = ( <div className="row d-md-none ">
        <div className="">
            <div className="" >
                <div className="px-5">
                    <div className="">
                        <div className="text-muted fw-bold mt-5 mb-2">On this Page</div>
                        {
                            linktsTemp
                        }
                    </div>
                </div>
            </div>
            <div className="">
                <div className="px-5 ps-md-5 ms-md-5">
                    {/*<div className="fs-4 fw-bold my-5">Terms and Conditions</div>
                    <div className="">
                        <p className="pb-3">
                            These Website Terms and Conditions (“Terms”) contained herein on this webpage, shall govern your access to and use of this website, including all pages within this website (collectively referred to as this “Website”).
                        </p>
                        <p className="pb-3">
                            These Terms apply in full force and effect to your use of this Website and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website, if you have any objection to any of these Terms.
                        </p>
                        <p className="pb-3">
                            PLEASE READ AND UNDERSTAND THE TERMS OF AGREEMENT CAREFULLY BEFORE BEING AGREED TO BE BOUND BY ITS TERMS.
                        </p>
                    </div>*/}
                    <div className="mt-5">
                        {aggreementsTemp}
                    </div>
                </div>
            </div>
        </div>
    </div>);
    const md = (<div className="row d-none d-md-block d-lg-none ">
        <div className="d-md-flex">
            <div className="col-md-8 ">
                <div className="px-5 ps-md-5 ms-md-5">
                    {/*<div className="fs-4 fw-bold my-5">Terms and Conditions</div>
                    <div className="">
                        <p className="pb-3">
                            These Website Terms and Conditions (“Terms”) contained herein on this webpage, shall govern your access to and use of this website, including all pages within this website (collectively referred to as this “Website”).
                        </p>
                        <p className="pb-3">
                            These Terms apply in full force and effect to your use of this Website and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website, if you have any objection to any of these Terms.
                        </p>
                        <p className="pb-3">
                            PLEASE READ AND UNDERSTAND THE TERMS OF AGREEMENT CAREFULLY BEFORE BEING AGREED TO BE BOUND BY ITS TERMS.
                        </p>
                    </div> */}
                    <div className="mt-5">
                        {aggreementsTemp}
                    </div>
                </div>
            </div>
            <div className="col-md-4" >
                <div className="px-5">
                    <div className="">
                        <div className="text-muted fw-bold mt-5 mb-2">On this Page</div>
                        {
                            linktsTemp
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>);
    const lg = ( <div className="row d-none d-lg-block d-xl-none  ">
        <div className="d-md-flex">
            <div className="col-md-9 ">
                <div className="px-5 ps-md-5 ms-md-5">
                    {/*<div className="fs-4 fw-bold my-5">Terms and Conditions</div>
                    <div className="">
                        <p className="pb-3">
                            These Website Terms and Conditions (“Terms”) contained herein on this webpage, shall govern your access to and use of this website, including all pages within this website (collectively referred to as this “Website”).
                        </p>
                        <p className="pb-3">
                            These Terms apply in full force and effect to your use of this Website and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website, if you have any objection to any of these Terms.
                        </p>
                        <p className="pb-3">
                            PLEASE READ AND UNDERSTAND THE TERMS OF AGREEMENT CAREFULLY BEFORE BEING AGREED TO BE BOUND BY ITS TERMS.
                        </p>
                    </div>*/}
                    <div className="mt-5">
                        {aggreementsTemp}
                    </div>
                </div>
            </div>
            <div className="col-md-3" >
                <div className="px-5">
                    <div className="">
                        <div className="text-muted fw-bold mt-5 mb-2">On this Page</div>
                        {
                            linktsTemp
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>);
    const xl = ( <div className="row d-none d-xl-flex px-75 ">
        <div className="d-md-flex">
            <div className="col-md-10 ">
                <div className="px-5 ps-md-5 ms-md-5">
                    {/*<div className="fs-4 fw-bold my-5">Terms and Conditions</div>
                    <div className="">
                        <p className="pb-3">
                            These Website Terms and Conditions (“Terms”) contained herein on this webpage, shall govern your access to and use of this website, including all pages within this website (collectively referred to as this “Website”).
                        </p>
                        <p className="pb-3">
                            These Terms apply in full force and effect to your use of this Website and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website, if you have any objection to any of these Terms.
                        </p>
                        <p className="pb-3">
                            PLEASE READ AND UNDERSTAND THE TERMS OF AGREEMENT CAREFULLY BEFORE BEING AGREED TO BE BOUND BY ITS TERMS.
                        </p>
                    </div>*/}
                    <div className="mt-5">
                        {aggreementsTemp}
                    </div>
                </div>
            </div>
            <div className="col-md-2" >
                <div className="px-5">
                    <div className="">
                        <div className="text-muted fw-bold mt-5 mb-2">On this Page</div>
                        {
                            linktsTemp
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>);

  return (
   <div>
       <div className="py-5 bg-orange text-white text-center fw-bold mb-5 fs-4">
           Terms and Conditions
       </div>
       {xs}
       {md}
       {lg}
       {xl}


   </div>
  );
};
