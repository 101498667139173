import React, {useRef} from "react";
import './styles.css';


export const Privacy = () => {
    const Overview = useRef(null);
    const Definition = useRef(null);
    const Objective = useRef(null);
    const Privacy = useRef(null);
    const Personal = useRef(null);
    const Information = useRef(null);
    const Use = useRef(null);
    const Security = useRef(null);
    const Disclosing = useRef(null);
    const Marketing = useRef(null);
    const Data = useRef(null);
    const Cookies = useRef(null);
    const Minor = useRef(null);
    const International = useRef(null);
    const Updates = useRef(null);
    const Contact = useRef(null);

    const conditions = [
        {
            link: Overview,
            name: '1. Consent',
            body: `You accept this privacy policy when you give consent upon access to our platforms, use the services offered on our website and digital platform or visit any of our offices for official or non-official purposes.`
        },
        {
            link: Definition,
            name: '2. What Personal Data do we collect?',
            body: `
            
            We collect Personal Data about you when you use the services offered on our website and digital platform, including the following:
1.\tSign-up information : When you register to use the services offered on our digital platform, we will collect Personal Data that is necessary to on-board you such as your name, email address, mobile phone number and debit card details. We may require you to provide additional personal details as you use our services.
2.\tTransaction information : When you use our digital platform to send and receive money, make purchases from merchants, pay bills, and deposit & withdraw cash, we collect information about the transaction, as well as other information associated with the transaction such as amount sent or received, amount paid for products or services, merchant information, including information about your device and geolocation.
3.\tParticipant Personal Data : When you use our services, we collect Personal Data about the other participants associated with the transaction. We collect Personal Data such as name and financial account information about the participant who is receiving money from you or sending money to you.
4.\tInformation from other sources : We may collect information from other sources, such as our social media platforms when you reach out to us to lodge a complaint about our services. However, we will only ask for information required to help us be of service to you.
5.\tOther information we collect related to your use of our website or Services : We may collect additional information from or about you when you communicate with us, contact our customer support teams or respond to a survey.
6.\tWhen you apply for a job with us : We may request Personal Data about your education, employment and state of health. As part of your application, you will be asked to provide your express consent to our use of this information to assess your application and any monitoring activities which may be required of us under applicable laws as an employer.
We may also carry out screening checks (including reference, background and criminal record checks).
We may exchange your Personal Data with academic institutions, recruiters, health maintenance organisations, law enforcement agencies, referees and your previous employers.
Without your Personal Data, we may not be able to process your application for positions with us.
We do not collect the information of minors.
If you are under the age of 18, you are not eligible to use the service offered on our digital platform.
`
        },
        {
            link: Objective,
            name: '3. How Do We Retain Personal Data?',
            body: `
We retain Personal Data in an identifiable format for at least seven (7) for our business purposes and to fulfil our legal or regulatory obligations. We may retain Personal Data for longer periods if it is in our legitimate business interests and required to comply with applicable laws. We will continue to use and disclose such Personal Data in accordance with this Privacy Policy.
             `
        },
        {
            link: Objective,
            name: '4. What do we do with your Personal Data?',
            body: `
            We collect your personal data to provide you an efficient and secure customer experience. We process your information to:
1.\tProvide services, including to:
•\tInitiate a payment, send or request money, or pay a bill
•\tAuthenticate your access to an account
•\tCommunicate with you about your account
2.\tManage risk, fraud and abuse of our services and you from fraud by verifying your identity.
3.\tComply with our obligations and to enforce the terms of our sites and services, including to comply with all applicable laws and regulations.
4.\tTrail information breach and remediate such identified breaches.
5.\tResolve disputes and troubleshoot problems.
6.\tWith your consent:
•\tMarket Wayapos products and services to you.
•\tUse cookies to provide a targeted display, feature, service or offer to you.
We will not send unsolicited marketing communications to you by SMS or email if you have not opted in to receive them.
Additionally, you can withdraw your consent at any time and free of charge.
             `
        },
        {
            link: Objective,
            name: '5. Sharing your Personal Data',
            body: `
            We may share your Personal Data or other information about you with others for the following reasons:
•\tWith other companies that provide services to us: We may share Personal Data with third-party service providers that perform services and functions at our direction and on our behalf. These third-party service providers may, for example, provide you with services, verify your identity, assist in processing transactions, send you advertisements for our products and services, or provide customer support.
•\tWith other financial institutions: We may share Personal Data with other financial institutions that we have partnered with to only offer Wayapos-related services, unless you have given consent for other uses. We may also share Personal Data to process transactions and keep your financial information up to date.
•\tWith the other parties to transactions when you use the services, such as other users and their service providers. This includes other users you are sending or receiving funds from and their service providers. The information might include:
•\tPersonal Data and account information necessary to facilitate the transaction; and
•\tPersonal Data to help other participant(s) resolve disputes and detect and prevent fraud.
•\tWith other third parties for our business purposes or as permitted or required by law: We may share information about you with other parties for Wayapos’s business purposes or as permitted or required by law, including:
0.\tIf we need to do so to comply with a law, legal process or regulations;
1.\tTo law enforcement authorities or other government officials, or other third parties pursuant to a court order or other legal process or requirement applicable to Wayapos or Wayapos’s corporate family;
2.\tIf we believe, in our sole discretion, that the disclosure of Personal Data is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation of suspected or actual illegal activity;
3.\tTo protect the vital interests of a person;
4.\tWith credit agencies and data processors for credit reference checks and anti-fraud and compliance purposes;
5.\tTo investigate violations of or enforce a user agreement or other legal terms applicable to any service;
6.\tTo protect our property, services and legal rights;
7.\tTo facilitate a purchase or sale of all or part of Wayapos’s business;
8.\tTo companies that we plan to merge with or be acquired by; and
9.\tTo support our audit, compliance, and corporate governance functions.
•\tWith your consent: We also will share your Personal Data and other information with your consent or direction.

             `
        },
        {
            link: Objective,
            name: '6. How We Use Cookies',
            body: `
            Cookies are small files placed on your device’s browser that enables the website to identify your device as you view different pages. Like most interactive websites, our website uses cookies to enable the tracking of your activity for the duration of a session. Our website uses only encrypted session cookies which are erased either after a predefined timeout period or once the user logs out of the platform and closes the browser. Session cookies do not collect information from your device. They will typically store data in the form of a session identification that does not personally identify you. Certain aspects of our website are only available through the use of cookies, so your use of our website may be limited or not possible if you choose to disable or decline cookies.

             `
        },
        {
            link: Objective,
            name: '7. What Are Your Rights?',
            body: `
            
1.\tRequests to Access, Rectify or Erase.
 .\tAccess Request
You have the right to ask us whether we hold any Personal Data relating to you and, if we do, to be provided with a copy of that Personal Data in electronic form, unless you want to receive it in another way (for example, a paper copy). In addition, you can ask us for information on how we use your Personal Data, who we share it with, how long we keep it, where it is stored, and other information to help you understand how we use it. \n
a.\tRectification Request
You have the right to ask us to correct your Personal Data (including by means of providing a supplementary statement) if it is inaccurate and to have incomplete Personal Data updated without undue delay. If we cannot correct the Personal Data, we include a note on our files regarding your request to correct your Personal Data. 
\nb.\tErasure Request
You have the right to ask us to erase your Personal Data if:
 .\tYour Personal Data are no longer necessary for the purpose(s) they were collected for.
i.\tYour Personal Data have been unlawfully processed.
ii.\tYour Personal Data must be erased to comply with a regulation.
iii.\tYou withdraw your consent for the processing of the Personal Data (and if this is the only basis on which we are processing your Personal Data).
iv.\tYou object to processing that is based on our legitimate interests, provided there are no overriding legitimate grounds for continued processing, or
v.\tYou object to processing for direct marketing purposes.
If we have made the Personal Data concerned public, we will also take reasonable steps to inform other data controllers processing the data so they can seek to erase links to or copies of your Personal Data. We may refuse to act on your request to erase your Personal Data if the processing of your Personal Data is necessary:
c.\tTo exercise our right of freedom of expression and information.
d.\tTo comply with the NDPR and relevant Nigerian laws.
e.\tFor the performance of a task carried out in the public interest or to exercise official authority vested in us.
f.\tTo establish, exercise or defend legal claims.
In these cases, we can restrict the processing instead of erasing your Personal Data if requested to do so by you. \n
2.\tRequests to Object: You have the right to object at any time to the processing of your Personal Data if we process it based on our legitimate interests. This includes any so-called “profiling”. Our privacy notice informs you when we rely on legitimate interests to process your Personal Data. In these cases, we will stop processing your Personal Data unless we can demonstrate compelling legitimate reasons for continuing the processing. We may reject your request if the processing of your Personal Data is needed to establish, exercise or defend legal claims. You have the right to object at any time if we process your Personal Data for direct marketing purposes. You may also object at any time to profiling supporting our direct marketing. In such cases, we will stop processing your Personal Data when we receive your objection.
3.\tRequests to Restrict: You have the right to ask us to restrict the processing of your Personal Data if:
•\tYou contest the accuracy of your Personal Data and we are in the process of verifying the Personal Data we hold.
•\tThe processing is unlawful and you do not want us to erase your Personal Data.
•\tWe no longer need your Personal Data for the original purpose(s) of processing, but you need them to establish, exercise or defend legal claims and you do not want us to delete the Personal Data as a result, or
•\tYou have objected to processing carried out because of our legitimate interests while we verify if our legitimate grounds override yours.
If processing is restricted, we may process your Personal Data (excepting for storage purposes), only:
•\tIf you have given us your consent.
•\tFor establishing, exercising or defending legal claims.
•\tFor protecting the rights of another natural or legal person, or
•\tFor reasons of important public interest as defined under the NDPR and relevant Nigerian laws.
Once processing is restricted following your request, we will inform you before we lift the restriction.
4.\tRequests for Portability: If our processing is performed by computer and is necessary to fulfil a contract with you, or is based on your consent, you have the right to:
•\tReceive any Personal Data you have provided to us in a structured, commonly used and machine-readable electronic format.
•\tSend your Personal Data to another organization or have us do so for you if it is technically feasible for us to do so.
If your request relates to a set of Personal Data that also concerns other individuals, the fact that you request that we port this data as described above does not preclude those individuals from exercising their own rights regarding their Personal Data.
Even if you request the portability of your Personal Data, you retain your right to also request their erasure.
5.\tRequests to Object to Automated Decisions: Generally, you have the right to object to any decision producing a legal effect concerning you or which otherwise significantly affects you if this is based solely on the automated processing of your Personal Data. This includes automated decisions based on profiling.
We may refuse your request if the decision in question is:
•\tNecessary to enter into a contract with you, or for the performance of your contract with us.
•\tPermitted by regulations, or
•\tBased on your explicit consent.
We will only make decisions relying solely on automated processing that involve your sensitive Personal Data if you have given your explicit consent or the processing is necessary for reasons of substantial public interest, based on the NDPR and relevant laws.

             `
        },
        {
            link: Objective,
            name: '8. How Do We Protect Your Personal Data?',
            body: `
            We maintain technical, physical, and administrative security measures designed to provide reasonable protection for your Personal Data against loss, misuse, unauthorised access, disclosure, and alteration. The security measures include firewalls, data encryption, physical access controls to our premises, CCTV cameras for public safety and quality control as well as information access authorisation controls. While we are dedicated to securing our systems and services, you are responsible for securing and maintaining the privacy of your password(s) and account/profile registration information and verifying that the Personal Data we maintain about you is accurate and current.
We will inform you of any breaches which may affect your Personal Data.
             `
        },
        {
            link: Objective,
            name: '9. Remedies for Violation and Timeframe for Remedy',
            body: `
            In the event of violation of this policy, our Data Protection Officer shall within 7 days redress the violation. Where the violation pertains to the disclosure of your Personal Data without your consent, such information shall be retracted immediately, and confirmation of the retraction sent to the you within 48 hours of the redress.

             `
        },
        {
            link: Objective,
            name: '10. Governing Law',
            body: `
            This Privacy Policy is made according to the Nigeria Data Protection Regulation (2019) or any other relevant Nigerian laws, regulations or international conventions applicable to Nigeria.

             `
        },
        {
            link: Objective,
            name: '11. Changes to our Privacy Policy',
            body: `
            This privacy policy is reviewed periodically and when there is any substantial change to business or regulatory requirements. At the minimum, we shall review this annually and communicate via our communication channels such as Website, Social Media Accounts etc.

             `
        },
        {
            link: Objective,
            name: '12. Contact Us',
            body: `
            If you have any general questions or concerns about this Privacy Policy or the way in which we handle your Personal Data, kindly contact us via the details below:

             `
        },
 {
            link: Objective,
            name: '13. Declaration',
            body: `
            I have read all the terms and conditions within this Notice and hereby agree/ disagree to use of my personal data for above mentioned purposes.
             `
        },

    ];
    const links = [
        {name: ' Overview ', to: Overview},
        {name: 'Definition ', to: Definition},
        {name: ' Objective ', to: Objective},
        {name: 'Our Privacy Principles ', to: Privacy},
        {name: ' Personal Information ', to: Personal},
        {name: 'How we get your Personal Information and why we have it ', to: Information},
        {name: 'How We May Use Your Personal Information ', to: Use},
        {name: 'Data Security and Retention ', to: Security},
        {name: 'Disclosing Your Personal Information ', to: Disclosing},
        {name: 'Marketing ', to: Marketing},
        {name: 'Your Data Protection Rights and Choices ', to: Data},
        {name: 'Cookies ', to: Cookies},
        {name: ' Minor ', to: Minor},
        {name: 'International Data Transfers ', to: International},
        {name: 'Updates to our privacy policy ', to: Updates},
        {name: 'Contact us ', to: Contact}
    ];

    const scrollToElement = (ref) => ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    const conditionsTemp = conditions.map((item) => (
        <div key={item.name} className="py-3" ref={item.link}>
            <div className="fs-4 fw-bold">{item.name}</div>
            <div className="">{item.body}</div>
        </div>
    ));
    const linktsTemp = (
        <div className="">
            {
                links.map((item) =>(
                    <div className="py-1 pointer link" key={item.name} onClick={() => scrollToElement(item.to)}>
                        {item.name}
                    </div>
                ))
            }
        </div>
    );
    const intro = (
        <div className="">
            <p className="pb-3">
                We are Flutterwave Incorporated, a Delaware corporation with offices at 1323 Columbus Avenue, San Francisco CA 94133 USA with company number 6031713
                <p className="pb-3">
                    These Terms apply in full force and effect to your use of this Website and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website, if you have any objection to any of these Terms.
                </p>
                <p className="pb-3">
                    WayaPay and its affiliates (collectively, “WayaPay Chat”) do not take your trusting us with your information for granted. The privacy of our Users is important to us and we are committed to safeguarding it. Hence, this Privacy Policy explains your Personal Information which we collect, why we collect it, and what we do with it.                        </p>
            </p>
        </div>
    );
    const xs = ( <div className="row d-md-none ">
        <p>
        </p>
        <div className="">
            <div className="" >
                <div className="px-5">
                    <div className="">
                        <div className="text-muted fw-bold mt-5 mb-2">On this Page</div>
                        {
                            linktsTemp
                        }
                    </div>
                </div>
            </div>
            <div className="">
                <div className="px-5 ps-md-5 ms-md-5">
                    <div className="fs-4 fw-bold my-5">Global Privacy Policy</div>
                    {intro}
                    <div className="mt-5">
                        {conditionsTemp}
                    </div>
                </div>
            </div>
        </div>
    </div>);
    const md = (<div className="row d-none d-md-block d-lg-none ">
        <div className="d-md-flex">
            <div className="col-md-8 ">
                <div className="px-5 ps-md-5 ms-md-5">
                    <div className="fs-4 fw-bold my-5">Global Privacy Policy</div>
                    {intro}
                    <div className="mt-5">
                        {conditionsTemp}
                    </div>
                </div>
            </div>
            <div className="col-md-4" >
                <div className="px-5">
                    <div className="">
                        <div className="text-muted fw-bold mt-5 mb-2">On this Page</div>
                        {
                            linktsTemp
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>);
    const lg = ( <div className="row d-none d-lg-block d-xl-none  ">
        <div className="d-md-flex">
            <div className="col-md-9 ">
                <div className="px-5 ps-md-5 ms-md-5">
                    <div className="fs-4 fw-bold my-5">Global Privacy Policy</div>
                    {intro}
                    <div className="mt-5">
                        {conditionsTemp}
                    </div>
                </div>
            </div>
            <div className="col-md-3" >
                <div className="px-5">
                    <div className="">
                        <div className="text-muted fw-bold mt-5 mb-2">On this Page</div>
                        {
                            linktsTemp
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>);
    const xl = ( <div className="row d-none d-xl-flex px-75 ">
        <div className="d-md-flex">
            <div className="col-md-10 ">
                <div className="px-5 ps-md-5 ms-md-5">
                    <div className="my-5">
                        At Wayapos the privacy of our Data Subjects’ Personal Data is of utmost importance to us. In line with our resolution, we have developed this Privacy Policy to explain your privacy rights regarding our collection, use, sharing and protection of your Personal Data when you visit our website, premisses or use our digital platforms.
                        This privacy policy between Wayapos and you constitute our commitment to your privacy on all our platforms. It is designed to provide information regarding our privacy practices and help you understand how we handle your data.
                    </div>
                    <div className="fs-4 fw-bold my-5">Global Privacy Policy</div>
                    {intro}
                    <div className="mt-5">
                        {conditionsTemp}
                    </div>
                </div>
            </div>
            <div className="col-md-2" >
                <div className="px-5">
                    <div className="">
                        <div className="text-muted fw-bold mt-5 mb-2">On this Page</div>
                        {
                            linktsTemp
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>);
  return (
   <div className="mb-3">
       <div className="py-5 bg-orange text-white text-center fw-bold mb-5 fs-4">
           Privacy Policy
       </div>
       {xs}
       {md}
       {lg}
       {xl}


   </div>
  );
};
