import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { IoArrowForward } from "react-icons/io5";
import { SVGS } from "../../assets/svgs";
import { icon } from "../career/jobUtils";
import { Row } from "../containers/containers";
import styles from "./styles.module.css";

const { googlePlay, appStore, downloadCircles, handPhone } = SVGS;

export const DownloadApp = () => {
  return (
    <Row className=" pt-3">
      <div className={styles.mainContainer}>
        <div
          className="col align-items-center"
          style={{ position: "relative", left: "-200px" }}
        >
          <Row>
            <div className="rotating pos-left-62">
              {icon({ src: downloadCircles, clss: "" })}
            </div>
            <div className="pulse" style={{ position: "absolute" }}>
              {icon({ src: handPhone, clss: "" })}
            </div>
          </Row>
        </div>
        <div className="col">
          <div className="download-title">
            <ScrollAnimation animateIn="animate__slideInRight">
              Download the mobile app
            </ScrollAnimation>
          </div>
          <div className="download-subtitle my-4">
            <ScrollAnimation animateIn="animate__slideInRight" delay={5}>
              Download Wayapos Mobile App from Playstore or App store to start
              managing your payments on the go.
            </ScrollAnimation>
          </div>
          <div className="d-flex">
            <div
              className="me-3 pointer"
              onClick={() =>
                window.open(process.env.REACT_APP_GOOGLE_URL, "_blank")
              }
            >
              <ScrollAnimation animateIn="animate__lightSpeedInLeft">
                <div>{icon({ src: googlePlay, clss: "" })}</div>
              </ScrollAnimation>
            </div>
            <div
              className="pointer"
              onClick={() =>
                window.open(process.env.REACT_APP_APPLE_URL, "_blank")
              }
            >
              <ScrollAnimation animateIn="animate__lightSpeedInRight">
                <div>{icon({ src: appStore, clss: "" })}</div>
              </ScrollAnimation>
            </div>
          </div>
          <button
            onClick={() =>
              window.open(process.env.REACT_APP_REGISTER_URL, "_blank")
            }
            className="btn download-btn text-white my-3"
          >
            Get Start with Wayapos now
            <span>
              <IoArrowForward />
            </span>
          </button>
        </div>
      </div>
    </Row>
  );
};
