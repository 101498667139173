import React from "react";
import "../animation.css";
import "./styles.css";

import ScrollAnimation from "react-animate-on-scroll";
import { SVGS } from "../../assets/svgs";
import { Vibrant } from "./vibrant";
import { Who } from "./who";

const { phone, menu, career6, career7 } = SVGS;

export const icon = ({ src, clss = "icon-holder-career p-4" }) => (
  <div>
    <span className={clss}>
      <img src={src} alt="" />
    </span>
  </div>
);
const numbs = [1, 2, 3, 4, 5, 6];
const jobs = {
  desc: "Product . Full-time . South Africa",
  status: "Ongoing",
  name: "Product Manager, South Africa Expansion",
  body: `As the Product Manager for our South African expansion efforts, you’ll be responsible for driving product innovation in South Africa that'll be targeted and helping out merchants start and scale their businesses. You’ll be the voice of the customer and will help build and maintain delightfully successful products that solve our users' pain points.`,
};
export const half = Math.ceil(numbs.length / 2);

export const rules1 = [
  {
    icon: icon({ src: menu }),
    name: "Health Insurance",
    body: `Real-time data and insights on your Wayapaychat Dashboard to make informed business decisions.`,
  },
  {
    icon: icon({ src: career7 }),
    name: "New MacBook",
    body: `You can test your dashboard before going live. No cost would beb incurred except you are ready to go live.`,
  },
  {
    icon: icon({ src: phone }),
    name: "Amazing Colleagues",
    body: `Follow your business performance stay up-to-date with revenue, payouts and recent activity on your business`,
  },
];
export const rules2 = [
  {
    icon: icon({ src: menu }),
    name: "Professional Development",
    body: `We give the the best to everyone to make sure they are able to achieve their goals.`,
  },
  {
    icon: icon({ src: career7 }),
    name: "Life Insurance",
    body: `We provide full-scale life cover for everyone at wayaPay`,
  },
  {
    icon: icon({ src: phone }),
    name: "Paid annual leave",
    body: "Everyone has 20 days of paid annual leave, and are warmly encouraged to use them",
  },
];
export const jobsCards = numbs.map((num) => (
  <div key={num} className="flex-md-row border-radius-21 p-4 my-4 card">
    <ScrollAnimation animateIn="animate__backInDown" animateOnce>
      <div className="d-md-none">
        <img src={career6} alt="" />
      </div>
    </ScrollAnimation>
    <ScrollAnimation animateIn="animate__backInDown" animateOnce>
      <div>
        <div className="">
          <small>{jobs.desc}</small>
        </div>
        <div className="pb-3 fw-bold mt-5 mb-3">{jobs.name}</div>
        <div className="pb-4">{jobs.body}</div>
        <div className="d-flex">
          <button className="bg-orange btn text-white">Apply Now</button>
          <div className="ms-2 pt-2">
            <small>status:</small>
            <small className="ms-1">{jobs.status}</small>
          </div>
        </div>
      </div>
    </ScrollAnimation>
    <ScrollAnimation animateIn="animate__backInDown" animateOnce>
      <div className="d-md-block d-none">
        <img src={career6} alt="" />
      </div>
    </ScrollAnimation>
  </div>
));

export const benefitsMap = ({
  items,
  animation = "animate__slideInLeft",
  animationInside = "animate__rollIn",
  clss = "text-center bg-white  border-radius-21  no-border",
  titleClss,
  bodyClss,
  customClassName,
}) =>
  items.map((item) => (
    <div key={item.name} className={`col ${customClassName}`}>
      <ScrollAnimation animateIn={animation}>
        <div key={item.name} className={`card ${clss}`}>
          <ScrollAnimation animateIn={animationInside} delay={1}>
            <div className={`contents`}>
              <div>{item.icon}</div>
              <div className={`p-2 fw-bold mt-2 mb-3 ${titleClss}`}>
                {item.name}
              </div>
              <div className={`p-2 ${bodyClss}`}>{item.body}</div>
            </div>
          </ScrollAnimation>
        </div>
      </ScrollAnimation>
    </div>
  ));
export const benefitsMapSm = (items) =>
  items.map((item) => (
    <div
      key={item.name}
      className="text-center bg-white p-5 m-3 border-radius-21 card no-border"
    >
      <ScrollAnimation animateIn="animate__backInDown" animateOnce>
        <div className=" ">
          <div>{item.icon}</div>
          <div className="pb-3 fw-bold mt-5 mb-3">{item.name}</div>
          <div className="pb-4">{item.body}</div>
        </div>
      </ScrollAnimation>
    </div>
  ));

export const screen = (
  <div className="">
    <div className="mt-5">
      {<Who />}
      {<Vibrant />}
    </div>
  </div>
);
