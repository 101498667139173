import React from "react";
import "../animation.css";
import { FooterGetStarted } from "../footerGetStarted/container";
//import { Benefits } from "./Benefits";
import { jobsCards, screen } from "./jobUtils";
import "./styles.css";
import { Vibrant } from "./vibrant";
import { Who } from "./who";

export const Career = () => {
  const xs = (
    <div className="row d-lg-none ">
      <div className="pe-5">
        {screen}
        {/* <Benefits
          title="Benefits and Perks"
          clss="text-center"
          arr={rules1}
          arr1={rules2}
        /> */}
        {jobsCards}
      </div>
    </div>
  );
  const lg = (
    <div className="row d-none d-lg-block d-xl-none px-5 ">
      <div className="d-md-flex">
        {screen}
        {/* <Benefits
          title="Benefits and Perks"
          clss="text-center"
          arr={rules1}
          arr1={rules2}
        /> */}
        {jobsCards}
      </div>
    </div>
  );
  const xl = (
    <div className="row d-none d-xl-flex ">
      <div className="row justify-content-center">
        <div className="w-75">
          {<Who />}
          {<Vibrant />}
        </div>
      </div>
      <div className="career-bg">
        <div className="justify-content-center d-flex">
          <div className="w-75">
            {/*  {
              <Benefits
                title="Benefits and Perks"
                clss="text-center"
                arr={rules1}
                arr1={rules2}
              />
            } */}
          </div>
        </div>
      </div>
      {/* <div className="justify-content-center d-flex my-5"> */}
      {/*    <div className="w-75"> */}
      {/*        {jobsCards} */}
      {/*    </div> */}
      {/* </div> */}
    </div>
  );

  return (
    <div className="mb-3">
      <div className="py-5 bg-orange text-white text-center mb-5 ">
        <div className="fw-bold fs-3 ">Work at WayaPay</div>
        <small>
          Come and work at wayapay to help grow and simplify payment services in
          Africa.
        </small>
        <div className="justify-content-center">
          <button className="bg-white pb-3 mt-2 text-center pointer text-orange btn no-outline align-items-center align-content-center white-button border-radius-4">
            See Available Positions
          </button>
        </div>
      </div>

      {xs}
      {lg}
      {xl}

      <FooterGetStarted />
    </div>
  );
};
