import React from "react";
import "react-phone-number-input/style.css";
import { SVGS } from "../../assets/svgs";
import { ButtonPlain } from "../buttons/buttons";
import { Col, Row } from "../containers/containers";
import { InputText } from "../inputs/inputs";
import { CardText, NormalTexts } from "../texts/texts";
import styles from "./styles.module.css";
// import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form-core'
import { useNavigate } from "react-router-dom";

const { Logo2 } = SVGS;
export const ForgotPassword = () => {
  const navigate = useNavigate();
  return (
    <Row className={styles.container}>
      <Col className={styles.formContainer}>
        <img src={Logo2} alt="" />
        <CardText top={25} color="#3A3837" size={24}>
          Forgot Password?
        </CardText>
        <NormalTexts top={16} size={16} color="#737171">
          Please enter your email or phone number to reset your password
          associated with your wayapay account
        </NormalTexts>
        <Col className={styles.inputsHolder}>
          <InputText width={"100%"} label={"Email Address"} noBorder={true} />

          <ButtonPlain
            top={20}
            left={-7}
            textColor={"#fff"}
            name={"Reset Password"}
            bgColor={"#FF6700"}
            onclick={() => navigate("/reset")}
          />
          <CardText top={10} size={15}>
            <a
              style={{ color: "#000000", textDecoration: "none" }}
              href="login"
            >
              Back to Sign In
            </a>
          </CardText>
          <CardText top={20} size={15} color="#828282">
            Don't have an account?{" "}
            <a
              style={{ color: "#FF6700", textDecoration: "none" }}
              href="register"
            >
              Sign up instead
            </a>
          </CardText>
        </Col>
      </Col>
    </Row>
  );
};
