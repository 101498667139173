import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { RiErrorWarningFill } from "react-icons/ri";
import { SVGS } from "../../assets/svgs";
import { Row } from "../containers/containers";
import { NormalTexts } from "../texts/texts";
import styles from "./normal.module.css";
const { payLogo, live_help } = SVGS;
export const InputText = ({
  type = "text",
  required = false,
  pass,
  reference,
  width,
  label,
  placeholder,
  under = false,
  pay,
  underText,
  onchange,
  cvv = false,
  underColor,
  noBorder,
}) => {
  // const handleKeyup=()=>{
  //   onchange()
  // }
  const [show, setshow] = useState(false);
  return (
    <div style={{ width: width }} className={`${styles.container}`}>
      <label htmlFor="thisInput">
        <NormalTexts size={12} color="#828282">
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </NormalTexts>
      </label>
      <div
        className={`${styles.input_container} ${
          noBorder && styles.inputnoBorder
        }`}
      >
        <input
          ref={reference}
          type={!show ? type : "text"}
          id="thisInput"
          className={styles.input}
          placeholder={placeholder}
          onKeyUp={onchange}
        />
        {cvv && <img className={styles.img} src={live_help} alt="" />}
        {pay && <img className={styles.img} src={payLogo} alt="" />}
        {pass &&
          (!show ? (
            <AiFillEyeInvisible
              style={{ cursor: "pointer" }}
              onClick={() => setshow(!show)}
              className={styles.img}
              size={24}
              color="#828282"
            />
          ) : (
            <AiFillEye
              style={{ cursor: "pointer" }}
              onClick={() => setshow(!show)}
              className={styles.img}
              size={24}
              color="#828282"
            />
          ))}
      </div>
      {under && (
        <Row>
          <RiErrorWarningFill
            color={underColor}
            size={24}
            style={{ marginRight: 5 }}
          />
          <NormalTexts size={10} align="left" color={underColor}>
            {underText}
          </NormalTexts>
        </Row>
      )}
    </div>
  );
};

export const InputSelect = ({
  label,
  required = false,
  placeholder,
  width,
  code = true,
  onchange,
  top,
}) => {
  return (
    <div
      style={{ width: width, marginTop: top }}
      className={`${styles.container}`}
    >
      <label htmlFor="thisInput">
        <NormalTexts size={12} color="#828282">
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </NormalTexts>
      </label>
      <div className={styles.input_container}>
        <select
          type="text"
          id="thisInput"
          className={`${styles.input} ${styles.select}`}
          placeholder={placeholder}
          onChange={onchange}
        >
          <option value="Edge">January</option>
          <option value="Edge">january</option>
          <option value="Edge">january</option>
          <option value="Edge">january</option>
          <option value="Edge">january</option>
        </select>
        {/* {code && (
          <div className={`${styles.code}`}>
            <code>*235*83#</code>
          </div>
        )} */}
      </div>
    </div>
  );
};

export const InputDate = ({ label, placeholder, width, min, max }) => {
  return (
    <div style={{ width: width }} className={`${styles.container}`}>
      <label htmlFor="thisInput">
        <NormalTexts size={12} color="#828282">
          {label}
        </NormalTexts>
      </label>
      <div className={styles.input_container}>
        <input
          type="number"
          id="thisInput"
          className={styles.input}
          placeholder={placeholder}
          min={`${min}`}
          max={`${max}`}
        />
      </div>
    </div>
  );
};
export const CheckboxalL = () => {
  return (
    <div class="grid2">
      <label class={`checkbox path`}>
        <input type="checkbox" />
        <svg viewBox="0 0 21 21">
          <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
        </svg>
      </label>
    </div>
  );
};

export const SingleInput = () => {
  return <input type="number" className={styles.single} />;
};
