import React from "react";
import { Button } from "../buttons/buttons";
import styles from "./styles.module.css";

export const NormalTexts = ({
  children,
  color = "#fff",
  align = "center",
  width,
  top,
  bottom,
}) => {
  return (
    <div
      style={{
        width,
        color: color,
        textAlign: align,
        marginTop: top,
        marginBottom: bottom,
      }}
      className={`${styles.ButtonsContainer} textIsh`}
    >
      {children}
    </div>
  );
};

export const Links = ({
  children,
  color = "#FF6700",
  size,
  align = "center",
  width,
  top,
  bottom,
  click,
  right = 15,
}) => {
  return (
    <Button
      onclick={click}
      style={{
        width,
        color: color,
        fontSize: size,
        textAlign: align,
        marginTop: top,
        marginBottom: bottom,
        marginRight: right,
      }}
      className={`${styles.ButtonsContainer} ${styles.link}`}
    >
      {children}
    </Button>
  );
};

export const HeaderText = ({
  children,
  color = "#333333",
  align = "center",
  size,
  top,
}) => {
  return (
    <h1
      style={{ color: color, textAlign: align, fontSize: size, marginTop: top }}
      className={`${styles.HeaderText} hearderIsh`}
    >
      {children}
    </h1>
  );
};

export const CardText = ({
  children,
  color = "#333333",
  size = 16,
  align = "left",
  top,
  bold,
}) => {
  return (
    <div
      style={{
        color: color,
        fontSize: size,
        textAlign: align,
        marginTop: top,
        fontWeight: bold,
      }}
      className={styles.HeaderText}
    >
      {children}
    </div>
  );
};
