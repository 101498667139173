import React from "react";
import "react-phone-number-input/style.css";
import { SVGS } from "../../assets/svgs";
import { ButtonPlain } from "../buttons/buttons";
import { Col, Row } from "../containers/containers";
import { InputText } from "../inputs/inputs";
import { CardText, NormalTexts } from "../texts/texts";
import styles from "./styles.module.css";
// import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form-core'
import logo from '../../assets/images/newLogo.png'

const { Logo2 } = SVGS;
export const NewPin = () => {
  return (
    <Row className={styles.container}>
      <Col className={styles.formContainer}>
        <img src={logo} width='150px' alt="" />
        <CardText top={25} color="#3A3837" size={24}>
          Reset your Pin
        </CardText>
        <NormalTexts top={16} size={16} color="#737171">
          Create your personalized pin to keep your acount safe and secure
        </NormalTexts>
        <Col className={styles.inputsHolder}>
          <Col align="flex-start">
            <NormalTexts size={10} color="#4F4F4F">
              New PIN
            </NormalTexts>
            <Row>
              <InputText width={"100%"} />
              <InputText width={"100%"} />
              <InputText width={"100%"} />
              <InputText width={"100%"} />
            </Row>
          </Col>
          <Col align="flex-start">
            <NormalTexts size={10} color="#4F4F4F">
              Confirm New PIN
            </NormalTexts>
            <Row>
              <InputText width={"100%"} />
              <InputText width={"100%"} />
              <InputText width={"100%"} />
              <InputText width={"100%"} />
            </Row>
          </Col>
          <ButtonPlain
            top={20}
            left={-7}
            textColor={"#fff"}
            name={"Reset Password"}
            bgColor={"#FF6700"}
          />

          <CardText top={20} size={15} color="#828282">
            <a style={{ color: "#828282", textDecoration: "none" }} href="#">
              Back To Sign in
            </a>
          </CardText>
        </Col>
      </Col>
    </Row>
  );
};
