import React from "react";
import { SVGS } from "../../assets/svgs";
import { Row } from "../containers/containers";
import { CardText } from "../texts/texts";
import styles from "./styles.module.css";

const { checked } = SVGS;
export const List = ({ name, textColor, bottom }) => {
  return (
    <Row style={{ marginBottom: bottom }} className={styles.ButtonsContainer}>
      <div className={styles.checkIcon}>
        <img src={checked} alt={name} />
      </div>
      <CardText align="left" color={textColor}>
        {name}
      </CardText>
    </Row>
  );
};
