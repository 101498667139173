import React from "react";
import { IoChevronForward } from "react-icons/io5";
import { SVGS } from "../../assets/svgs";
import { Button } from "../buttons/buttons";
import { Col, Row } from "../containers/containers";
import { HeaderText } from "../texts/texts";
import styles from "./styles.module.css";
const { home, mapNav } = SVGS;

export const Addresses = () => {
  return (
    <Row className="py-5">
      <Row className={styles.subContainer} justify="space-between">
        <Col
          justify="flex-start"
          align="flex-start"
          className={styles.eachFlex}
        >
          <HeaderText size={36} color="#FF4B01">
            <div className="fw-bold">Contact Info</div>
          </HeaderText>
          <div className={styles.tagLine}>
            You are welcome to reach us at any of the contacts below
          </div>
          <Col
            justify="flex-start"
            align="flex-start"
            className={styles.colWidth}
          >
            <Col align="flex-start">
              <Row>
                <div>{<img src={home} alt="" />}</div>
                <div style={{ marginLeft: "15px" }}>
                  <div className={styles.headers}>Office Address</div>
                </div>
              </Row>
              <div className={styles.address} style={{ marginLeft: "50px" }}>
                14, Yeye Olofin Street. Lekki Phase 1, Lagos, Nigeria.
              </div>
            </Col>
            <Col align="flex-start">
              <Row>
                <div>{<img src={mapNav} alt="" />}</div>
                <div className={styles.address} style={{ marginLeft: "15px" }}>
                  info@wayapos.ng
                </div>
              </Row>
            </Col>
          </Col>
          {/*<Row>*/}
          {/*    <div style={{margin: '30px 0'}}>*/}
          {/*        <img src={map} alt=""/>*/}
          {/*    </div>*/}
          {/*</Row>*/}
        </Col>
        <Col className={`${styles.eachFlex} ${styles.another}`}>
          <HeaderText size={26} color="#FF4B01">
            Get In Touch
          </HeaderText>
          <Row className={styles.container}>
            <Col className={styles.formContainer}>
              <form action="">
                <Row>
                  {/*<label className={styles.label}>*/}
                  {/*    <span>Name</span>*/}
                  {/*    <span style={{color: 'red'}}>*</span>*/}
                  {/*</label>*/}
                  <input
                    className={styles.input}
                    type="text"
                    name="name"
                    placeholder="Name"
                  />
                </Row>
                <Row>
                  {/*<label className={styles.label}>*/}
                  {/*     <span>Email</span>*/}
                  {/*     <span style={{color: 'red'}}>*</span>*/}
                  {/* </label>*/}
                  <input
                    className={styles.input}
                    type="text"
                    name="name"
                    placeholder="Email"
                  />
                </Row>
                <Row>
                  {/*<label className={styles.label}>*/}
                  {/*    <span>Phone Number</span>*/}
                  {/*    <span style={{color: 'red'}}>*</span>*/}
                  {/*</label>*/}
                  <input
                    className={styles.input}
                    type="text"
                    name="name"
                    placeholder="Phone Number"
                  />
                </Row>
                <Row>
                  {/*<label className={styles.label}>*/}
                  {/*    <span>Subject</span>*/}
                  {/*    <span style={{color: 'red'}}>*</span>*/}
                  {/*</label>*/}
                  <input
                    className={styles.input}
                    type="text"
                    required
                    name="name"
                    placeholder="Subject"
                  />
                </Row>

                <div>
                  <textarea
                    className={styles.textArea}
                    cols="20"
                    name="name"
                    placeholder="Name"
                  />
                </div>
                <Col align="start">
                  <Row>
                    <div style={{ marginTop: "10px" }}>
                      <Button
                        bgColor={"#FF4B01"}
                        onclick={() => console.log("send")}
                        name={`Send`}
                      >
                        <IoChevronForward
                          color="#ffffff"
                          style={{ marginLeft: 10, color: "#fff" }}
                          size={16}
                        />
                      </Button>
                    </div>
                  </Row>
                </Col>
              </form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};
